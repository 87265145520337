
import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Menu,
  MenuItem,
  Box
} from '@mui/material';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

const Jobs = () => {
    const[jobsData,setJobsData]=useState([]);
    const [openCreateJobPopup,SetOpenCreateJobPopup]=useState(false);
    const [selectedJobDetails, setSelectedJobDetails] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [selectedTab, setSelectedTab] = useState('details');
    const [selectedJob, setSelectedJob] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    
    const[newJobData,setNewJobData]=useState({

      "title":"",
      "description":"",
      "min_experience":"",
      "location":"",
      "salary":""

    })

    const[editedEventData,setEditedEventData]=useState({
      "title":"",
      "description":"",
      "min_experience":"",
      "location":"",
      "salary":""
  });

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const fetchJobs=async()=>{
        const response =await axios.get(`${backendUrl}/api/jobs/`)
        console.log(response)
        setJobsData(response.data);
    }

    const handleCreateJobs=async()=>{
      try{
        const payload={
          title:newJobData.title,
          description:newJobData.description,
          min_experience:newJobData.min_experience,
          location:newJobData.location,
          salary:newJobData.salary
        }

        const response=await axios.post(`${backendUrl}/api/jobs/`,payload);
        fetchJobs();
        handleCloseCreateJobPopup();

      }
      catch(err){
        console.log(err);
      }
    }
    const handleOpenCreateJobPopup = () => {
      SetOpenCreateJobPopup(true)
    };

    const handleCloseCreateJobPopup = () => {
      SetOpenCreateJobPopup(false);
    };

    const handleChange = (e, isCreating) => {
      const { name, value } = e.target;
      if (isCreating) {
        setNewJobData(prevData => ({
          ...prevData,
          [name]: value
        }));
      } 
      else {
        setEditedEventData(prevData => ({
          ...prevData,
          [name]: value
        }));
      }
      
    };

    useEffect(()=>{
            fetchJobs();
    },[])

    const handleOpenDetailsDialog = (jobs) => {
      setSelectedJobDetails(jobs);
      setOpenDetailsDialog(true);
      console.log("Selected Job Details:", jobs);
    };
    
  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
  };


  const handleOpenEditDialog = (jobs) => {
  setSelectedJob(jobs);
  const editedData = { ...jobs };
  setEditedEventData(editedData);
  setOpenEditDialog(true); // Make sure this line is present
};

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveChanges = async () => {
    try {
      // Check if selectedEvent is not null
      if (!selectedJob) {
        console.error("No event selected for editing.");
        return;
      }
  
      // Send PUT request to update event data
      const response = await axios.put(`${backendUrl}/api/jobs/${selectedJob.id}`, editedEventData);
  
      // Check if the request was successful (status code 2xx)
      if (response.status === 200 || response.status === 201) {
        // Fetch updated event data after successful update
        fetchJobs();
        handleCloseEditDialog();
      } else {
        // Log error if response status code is not in the 2xx range
        console.error("Failed to update event data. Unexpected status code:", response.status);
      }
    } catch (err) {
      // Log detailed error message
      console.error("Error updating event data:", err);
  
      // Check if the error has a response and log the server error message
      if (err.response) {
        console.error("Server error message:", err.response.data);
      }
    }
  };
  return (
    <div>
        <h2>Job Information</h2>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
        <Button variant="contained" onClick={handleOpenCreateJobPopup} >
          Add Jobs
        </Button>
      </div>
        <Table>
  <TableHead style={{ backgroundColor: '#DCD2D2', fontWeight: 'bold' }}>
    <TableRow>
      <TableCell style={{ fontWeight: 'bold' }}>Jobs Title</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Location</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Min experience</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Salary</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Posted</TableCell>
      {/* <TableCell style={{ fontWeight: 'bold' }}>Activites Name</TableCell> */}
      <TableCell sx={{ fontWeight: 'bold', textAlign: 'right'}}>Actions</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {jobsData.map(jobs => (
      <TableRow key={jobs.id}>
        <TableCell>{jobs.title}</TableCell>
        <TableCell>{jobs.description || '-'}</TableCell>
        <TableCell>{jobs.location || 'null'}</TableCell>
        <TableCell>{jobs.min_experience || 'null'}</TableCell>
        <TableCell>{jobs.salary || '-'}</TableCell>
        <TableCell>{jobs.published_at ? new Date(jobs.published_at).toLocaleDateString() : '-'}</TableCell>

        {/* <TableCell>{jobs.activities || '-'}</TableCell> */}
       
        <TableCell>
                  <div style={{display:'flex', justifyContent:'flex-end'}}>
                  <Button variant="contained"  color="primary"  onClick={()=>handleOpenDetailsDialog(jobs)} sx={{ marginRight: '8px' }}>Details</Button>
                  <Button variant="contained"  color="primary" onClick={()=>handleOpenEditDialog(jobs)} >Edit</Button>
                  
                  </div>
                  <Menu
                   
                  >
                    <MenuItem>Enroll</MenuItem>
                    <MenuItem>Register</MenuItem>
                  </Menu>
                </TableCell>
      </TableRow>
    ))}
  </TableBody>
</Table>

<Dialog open={openCreateJobPopup} onClose={handleCloseCreateJobPopup}>
        <DialogTitle>Create Activity</DialogTitle>
        <DialogContent sx={{ width: '400px' }}>
        <Typography variant="subtitle1" gutterBottom>Job Title</Typography>
        <TextField
  fullWidth
  label="Job Name"
  name="title"
  value={newJobData.title}
  onChange={(e) => handleChange(e, true)}
  margin="normal"
/>

<Typography variant="subtitle1" gutterBottom>Description</Typography>
<TextField
  fullWidth
  label="description"
  name="description"
  type="textfield"
  value={newJobData.description}
  onChange={(e) => handleChange(e, true)}
  margin="normal"
  InputLabelProps={{
    shrink: true,
  }}
/>

<Typography variant="subtitle1" gutterBottom>Min Experiance</Typography>
<TextField
  fullWidth
  label="Min Experience"
  name="min_experience"
  
  value={newJobData.min_experience}
  onChange={(e) => handleChange(e, true)}
  margin="normal"
  InputLabelProps={{
    shrink: true,
  }}
/>
<Typography variant="subtitle1" gutterBottom>Location</Typography>
<TextField
  fullWidth
  label="location"
  name="location"
  value={newJobData.location}
  onChange={(e) => handleChange(e, true)}
  margin="normal"
/>

<Typography variant="subtitle1" gutterBottom>Salary</Typography>
<TextField
  fullWidth
  label="salary"
  name="salary"
  value={newJobData.salary}
  onChange={(e) => handleChange(e, true)}
  margin="normal"
/>
{/* <TextField
  fullWidth
  label="Activities"
  name="activities"
  value={newJobData.activities}
  onChange={(e) => handleChange(e, true)}
  margin="normal"
/> */}

          
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseCreateJobPopup}  color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleCreateJobs} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
  open={openDetailsDialog}
  onClose={handleCloseDetailsDialog}
  PaperProps={{
    style: {
      width: '80vw', // Adjust the width as needed
      height: '100vh', // Adjust the height as needed
      margin: 'auto',
    },
  }}
>
  {/* <DialogTitle>Activity Details</DialogTitle> */}
  <DialogContent>
    <Tabs
      value={selectedTab}
      onChange={(event, newValue) => setSelectedTab(newValue)}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab label="Details" value="details" sx={{ color: 'black', fontWeight: 'bold' }} />
      <Tab label="Enroll" value="enroll" sx={{ color: 'black', fontWeight: 'bold' }}/>
      <Tab label="Reserve" value="reserve" sx={{ color: 'black', fontWeight: 'bold' }} />
    </Tabs>
    {selectedTab === 'details' && (
  <div>
    <Box sx={{ textAlign: 'center', width: '100%', padding: '20px' }}>
    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        Job Details
    </Typography>
</Box>

    <TableContainer>
  <Table>
    <TableBody>
      <TableRow>
        <TableCell>Job Title</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.title : ''}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Description</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.description : ''}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Location</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.location : 'Null'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Min Experience</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.min_experience : 'Null'}</TableCell>
      </TableRow>
     
    </TableBody>
  </Table>
</TableContainer>

  </div>
)}

    {selectedTab === 'enroll' && (
      <div>
        {/* Enroll content */}
      </div>
    )}
    {selectedTab === 'reserve' && (
      <div>
        {/* Reserve content */}
      </div>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDetailsDialog} color="primary" variant='contained'>
      Close
    </Button>
  </DialogActions>
</Dialog>


<Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
<DialogTitle sx={{ fontWeight: 'bold',textAlign:'center' }}>Edit Job</DialogTitle>
        <DialogContent>
        <Typography variant="subtitle1" gutterBottom>Event Name</Typography>
          <TextField
            fullWidth
            // label="Event Name"
            placeholder='Event Name'
            name="title"
            value={editedEventData.title}
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>Entry Price</Typography>
          <TextField
            fullWidth
            // label="HEntry"
            name="description"
            value={editedEventData.description}
            onChange={handleChange}
            margin="normal"
          />

{/* <Typography variant="subtitle1" gutterBottom>Registered Peoples</Typography>
          <TextField
            fullWidth
            // label="Monthly Fees"
            name="registered_peoples"
            value={editedEventData.registered_peoples}
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>Maximum Participants</Typography>
          <TextField
            fullWidth
            // label="Max Team Size"
            name="maximum_participants"
            value={editedEventData.maximum_participants}
            onChange={handleChange}
            margin="normal"
          /> */}

<Typography variant="subtitle1" gutterBottom>Start Date</Typography>
          <TextField
            fullWidth
            // label="Max Time Limit"
            name="location"
            value={editedEventData.location}
            
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>End Date</Typography>
          <TextField
            fullWidth
            // label="Available Court"
            name="min_experience"
            value={editedEventData.min_experience}
        
            onChange={handleChange}
            margin="normal"
          />

<TextField
            fullWidth
            // label="Available Court"
            name="salary"
            value={editedEventData.salary}
            
            onChange={handleChange}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} variant='contained' color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveChanges} variant='contained' color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

export default Jobs