import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Menu,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import FinanceSearch from '../SearchComponent/FinanceSearch';

const PaidInvoice = () => {
  const [activityData, setActivityData] = useState([]);
  const [openCreateActivityPopup, setOpenCreateActivityPopup] = useState(false);
  const [newActivityData, setNewActivityData] = useState({
    activity_name: '',
    current_hourly_fees: '',
    current_monthly_fees: '',
    max_team_size: '',
    max_time_limit: '',
    available_court: ''
  });
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [editedActivityData, setEditedActivityData] = useState({
    "user": "",
    "first_name":"",
    "last_name": "",
    "invoice_type": "",
    "sports": "",
    "amount": "",
    "date_created": "",
    "is_paid": "",
  });
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [selectedActivityDetails, setSelectedActivityDetails] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState('details'); // Set default tab to 'details'
  const [searchParams, setSearchParams] = useState({
    firstName: '',
    lastName: '',
    userId: '',
    sportsName: '',
    memberId: '',
  });
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [paginationMeta, setPaginationMeta] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  useEffect(() => {
    fetchData(page);
  }, [page]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const fetchData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/paidinvoice?page=${page}`);
      const paidinvoice = response.data;
      console.log("Fetched paidinvoice data:", paidinvoice); // Check the structure here
      setActivityData(paidinvoice.results || []); // Ensure activityData is an array
      setFilteredData(paidinvoice.results || []); // Ensure filteredData is an array
      setTotalPages(Math.ceil(response.data.count / 10)); // Assuming page_size is 1, adjust if necessary
      setPaginationMeta({
        count: response.data.count,
        next: response.data.next,
        previous: response.data.previous,
      });
    } catch (error) {
      console.log(error);
    }
  };
  
  
  const handlePreviousPage = () => {
    if (paginationMeta.previous) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (paginationMeta.next) {
      setPage(page + 1);
    }
  };

  const buttonStyle = {
    margin: '8px',
    padding: '8px 16px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    cursor: 'pointer',
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#b0bec5',
    cursor: 'not-allowed',
  };

  const handleSearch = (searchParams) => {
    const filtered = activityData.filter(activity => {
      return (
        (!searchParams.firstName || activity.first_name.toLowerCase().includes(searchParams.firstName.toLowerCase())) &&
        (!searchParams.lastName || activity.last_name.toLowerCase().includes(searchParams.lastName.toLowerCase())) &&
        (!searchParams.userId || activity.users_id.toString().includes(searchParams.userId)) &&
        (!searchParams.telephoneNumber || activity.telephoneNumber?.toString().includes(searchParams.telephoneNumber)) &&
        (!searchParams.email || activity.email?.toLowerCase().includes(searchParams.email.toLowerCase())) &&
        (!searchParams.location || activity.location?.toLowerCase().includes(searchParams.location.toLowerCase())) &&
        (!searchParams.language || activity.language?.toLowerCase().includes(searchParams.language.toLowerCase())) &&
        (!searchParams.sportsName || activity.sports.toLowerCase().includes(searchParams.sportsName.toLowerCase())) &&
        (!searchParams.memberId || activity.memberId?.toString().includes(searchParams.memberId))
      );
    });
    setFilteredData(filtered);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedActivityData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleOpenCreateActivityPopup = () => {
    setOpenCreateActivityPopup(true);
  };

  const handleCloseCreateActivityPopup = () => {
    setOpenCreateActivityPopup(false);
  };

  const handleCreateActivity = async () => {
    try {
      await axios.post(`${backendUrl}/api/activity/`, newActivityData);
      fetchData();
      handleCloseCreateActivityPopup();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleOpenDetailsDialog = (activity) => {
    setSelectedActivityDetails(activity);
    setOpenDetailsDialog(true);
  };

  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
  };

  const handleEnrollRegisterClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <h2>Finance Information</h2>
      <FinanceSearch onSearch={handleSearch} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}></div>
      
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: '#DCD2D2', fontWeight: 'bold' }}>
            <TableRow>
              {/* <TableCell style={{ fontWeight: 'bold' }}>User Id</TableCell> */}
              <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Invoice Type</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Sports</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Date Payed</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Is Paid</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'right'}}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((activity) => (
              <TableRow key={activity.id}>
                {/* <TableCell>{activity.users_id}</TableCell> */}
                <TableCell>{activity.first_name}</TableCell>
                <TableCell>{activity.last_name}</TableCell>
                <TableCell>{activity.invoice_type}</TableCell>
                <TableCell>{activity.sports}</TableCell>
                <TableCell>{activity.amount}</TableCell>
                <TableCell>
    {activity.date_payed
        ? new Date(activity.date_payed).toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // This ensures the 12-hour format with AM/PM
        })
        : '-'}
</TableCell>

                <TableCell>{activity.is_paid ? 'Paid' : 'Unpaid'}</TableCell>
                <TableCell>
                  <div style={{ display: 'flex', justifyContent:'flex-end' }}>
                    <Button variant="contained" color="primary" onClick={() => handleOpenDetailsDialog(activity)}>Details</Button>
                  </div>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleEnrollRegisterClose}
                  >
                    <MenuItem onClick={() => handleEnrollRegisterClose()}>Enroll</MenuItem>
                    <MenuItem onClick={() => handleEnrollRegisterClose()}>Register</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button 
                    onClick={handlePreviousPage}
                    style={page <= 1 ? disabledButtonStyle : buttonStyle}
                    disabled={page <= 1}
                >
                    Previous
                </Button>
                <Button 
                    onClick={handleNextPage}
                    style={page >= totalPages ? disabledButtonStyle : buttonStyle}
                    
                >
                    Next
                </Button>
            </div>
      </TableContainer>
      <Dialog
        open={openDetailsDialog}
        onClose={handleCloseDetailsDialog}
        maxWidth={false}
    PaperProps={{
      style: {
        width: '80vw', // Adjust the width as needed
        height: '100vh', // Adjust the height as needed
        margin: 'auto',
      },
    }}
      >
        <DialogContent>
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => setSelectedTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Details" sx={{fontWeight:'bolder',color:'#000'}} value="details" />
            <Tab label="Enroll" sx={{fontWeight:'bolder',color:'#000'}} value="enroll" />
            <Tab label="Reserve" sx={{fontWeight:'bolder',color:'#000'}} value="reserve" />
          </Tabs>
          {selectedTab === 'details' && (
            <div>
              <Typography variant="h6">Activity Details</Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>User Id</TableCell>
                      <TableCell>{selectedActivityDetails ? selectedActivityDetails.users_id : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>{selectedActivityDetails ? selectedActivityDetails.first_name : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Last Image</TableCell>
                      <TableCell>{selectedActivityDetails ? selectedActivityDetails.last_name : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Invoice Type</TableCell>
                      <TableCell>{selectedActivityDetails ? selectedActivityDetails.invoice_type : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Sports</TableCell>
                      <TableCell>{selectedActivityDetails ? selectedActivityDetails.sports : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Amount</TableCell>
                      <TableCell>{selectedActivityDetails ? selectedActivityDetails.amount : ''}</TableCell>
                    </TableRow>
                    <TableRow>
    <TableCell>Date Created</TableCell>
    <TableCell>
        {selectedActivityDetails && selectedActivityDetails.date_created
            ? new Date(selectedActivityDetails.date_created).toLocaleDateString('en-US', {
                month: 'short',
                day: '2-digit',
                year: 'numeric',
            })
            : ''}
    </TableCell>
</TableRow>

                    <TableRow>
                      <TableCell>Is Paid</TableCell>
                      <TableCell>{selectedActivityDetails ? selectedActivityDetails.is_paid : ''}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          {selectedTab === 'enroll' && (
            <div>
              {/* Enroll content */}
            </div>
          )}
          {selectedTab === 'reserve' && (
            <div>
              {/* Reserve content */}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetailsDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaidInvoice;
