import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { useMediaQuery, Menu, MenuItem, IconButton, Drawer, List, ListItem, ListItemText, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

export default function NavBar() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [adminAnchorEl, setAdminAnchorEl] = useState(null);
  const [financeAnchorEl, setFinanceAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [trainingAnchorEl, setTrainingAnchorEl] = useState(null);
  const [eventAnchorEl, setEventAnchorEl] = useState(null);

  const handleAdminSubMenuToggle = (event) => {
    setAdminAnchorEl(event.currentTarget);
  };

  const handleFinanceSubMenuToggle = (event) => {
    setFinanceAnchorEl(event.currentTarget);
  };

  const handleCloseAdminSubMenu = () => {
    setAdminAnchorEl(null);
  };

  const handleCloseFinanceSubMenu = () => {
    setFinanceAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const handleTrainingSubMenuToggle = (event) => {
    setTrainingAnchorEl(event.currentTarget);
  };

  const handleEventSubMenuToggle = (event) => {
    setEventAnchorEl(event.currentTarget);
  };

  const handleCloseTrainingSubMenu = () => {
    setTrainingAnchorEl(null);
  };

  const handleCloseEventSubMenu = () => {
    setEventAnchorEl(null);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        App Name
      </Typography>
      <List>
        <ListItem button component={NavLink} to="/home">
          <ListItemText primary="Homes" />
        </ListItem>
        <ListItem button component={NavLink} to="/members">
          <ListItemText primary="Members" />
        </ListItem>
        <ListItem button component={NavLink} to="/training">
          <ListItemText primary="Training" />
        </ListItem>
        <ListItem button component={NavLink} to="/coaches">
          <ListItemText primary="Coach" />
        </ListItem>
        <ListItem button component={NavLink} to="/visitors">
          <ListItemText primary="Visitors" />
        </ListItem>
        <ListItem button component={NavLink} to="/finance">
          <ListItemText primary="Finance" />
        </ListItem>
        <ListItem button component={NavLink} to="/crm">
          <ListItemText primary="CRM" />
        </ListItem>
        <ListItem button component={NavLink} to="/sports">
          <ListItemText primary="Sports" />
        </ListItem>
        {/* <ListItem button component={NavLink} to="/activities">
          <ListItemText primary="Activities" />
        </ListItem> */}
        <ListItem button component={NavLink} to="/events">
          <ListItemText primary="Events" />
        </ListItem>
        <ListItem button component={NavLink} to="/jobs">
          <ListItemText primary="Jobs" />
        </ListItem>
        <ListItem button component={NavLink} to="/sponsors">
          <ListItemText primary="Donations" />
        </ListItem>
        <ListItem button component={NavLink} to="/facilities">
          <ListItemText primary="Facilities" />
        </ListItem>
        <ListItem button component={NavLink} to="/admin/users">
          <ListItemText primary="Admin" />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component={NavLink} to="/home" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
            Home
          </Typography>
          {isMobile ? (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                }}
              >
                {drawer}
              </Drawer>
            </>
          ) : (
            <>
              <Typography variant="subtitle1" component={NavLink} to="/members" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                Members
              </Typography>
              <Typography variant="subtitle1" component={NavLink} to="/training" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }} onClick={handleTrainingSubMenuToggle}>
                Training
              </Typography>
              <Menu
                anchorEl={trainingAnchorEl}
                open={Boolean(trainingAnchorEl)}
                onClose={handleCloseTrainingSubMenu}
              >
                <MenuItem component={NavLink} to="/booked-trainings" onClick={handleCloseTrainingSubMenu}>Booked Trainings</MenuItem>
              </Menu>
              <Typography variant="subtitle1" component={NavLink} to="/coaches" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                Coach
              </Typography>
              <Typography variant="subtitle1" component={NavLink} to="/admin/users" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                Visitors
              </Typography>
              <Typography variant="subtitle1" component={NavLink} to="/finance" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }} onClick={handleFinanceSubMenuToggle}>
                Finance
              </Typography>
              <Menu
                anchorEl={financeAnchorEl}
                open={Boolean(financeAnchorEl)}
                onClose={handleCloseFinanceSubMenu}
              >
                {/* <MenuItem component={NavLink} to="/finance/open-invoice" onClick={handleCloseFinanceSubMenu}>Open Invoice</MenuItem> */}
                <MenuItem component={NavLink} to="/paid-invoice" onClick={handleCloseFinanceSubMenu}>Paid Invoice</MenuItem>
              </Menu>
              <Typography variant="subtitle1" component={NavLink} to="/crm" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                CRM
              </Typography>
              <Typography variant="subtitle1" component={NavLink} to="/sports" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                Sports
              </Typography>
              {/* <Typography variant="subtitle1" component={NavLink} to="/activities" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                Activities
              </Typography> */}
              <Typography           variant="subtitle1" component={NavLink} to="/events" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }} onClick={handleEventSubMenuToggle}>
                Events
              </Typography>
              <Menu
                anchorEl={eventAnchorEl}
                open={Boolean(eventAnchorEl)}
                onClose={handleCloseEventSubMenu}
              >
                <MenuItem component={NavLink} to="/booked-events" onClick={handleCloseEventSubMenu}>Booked Events</MenuItem>
              </Menu>
              <Typography variant="subtitle1" component={NavLink} to="/jobs" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                Jobs
              </Typography>
              <Typography variant="subtitle1" component={NavLink} to="/sponsors" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                Sponsors
              </Typography>
              <Typography variant="subtitle1" component={NavLink} to="/facilities" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                Facilities
              </Typography>
              {/* <Typography variant="subtitle1" component={NavLink} to="/Terms" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                Terms and Conditions
              </Typography> */}
              <div>
                <Typography
                  variant="subtitle1"
                  onClick={handleAdminSubMenuToggle}
                  sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1, cursor: 'pointer' }}
                >
                  Admin
                </Typography>
                <Menu
                  anchorEl={adminAnchorEl}
                  open={Boolean(adminAnchorEl)}
                  onClose={handleCloseAdminSubMenu}
                >
                  <MenuItem component={NavLink} to="/admin/users" onClick={handleCloseAdminSubMenu}>Users</MenuItem>
                  <MenuItem component={NavLink} to="/reporting" onClick={handleCloseAdminSubMenu}>Reporting</MenuItem>
                  <MenuItem component={NavLink} to="/admin/admin2" onClick={handleCloseAdminSubMenu}>Admin 2</MenuItem>
                  <MenuItem component={NavLink} to="/permissions" onClick={handleCloseAdminSubMenu}>Permissions</MenuItem>
                </Menu>
              </div>
              <Button color="inherit" onClick={handleLogout}>
                Logout
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

