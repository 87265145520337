import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
// import Razorpay from 'razorpay';

const Events = () => {
  const [eventData, setEvents] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [users, setUsers] = useState([]);
  const [openCreateEventPopup, setOpenCreateEventPopup] = useState(false);
  const [newEventData, setNewEventData] = useState({
    name: '',
    start_date: '',
    end_date: '',
    entry_price: '',
  });

  const [editedEventData, setEditedEventData] = useState({
    name: '',
    start_date: '',
    end_date: '',
    entry_price: '',
  });

  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [selectedTab, setSelectedTab] = useState('details');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  // State for booking
  const [openBookingDialog, setOpenBookingDialog] = useState(false);
  const [eventbookingData, seteventBookingData] = useState({
    event: '',
    user: '',
  });
  const [searchPhoneNumber, setSearchPhoneNumber] = useState('');
  const [searchedUser, setSearchedUser] = useState(null); // State to store searched user
  const [filteredPhoneNumbers, setFilteredPhoneNumbers] = useState([]);

  const [phoneQuery, setPhoneQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState('');

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [paginationMeta, setPaginationMeta] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  useEffect(() => {
    fetchEvents(page);
  }, [page]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/events?page=${page}`);
      const events = response.data;
      console.log("Fetched events data:", events); // Check the structure here
      setEvents(events.results || []); // Ensure eventsData is an array
      setTotalPages(Math.ceil(events.count / 10)); // Assuming page_size is 10, adjust if necessary
      setPaginationMeta({
        count: events.count,
        next: events.next,
        previous: events.previous,
      });
    } catch (err) {
      console.error("Error fetching events:", err);
    }
  };

  const handlePreviousPage = () => {
    if (paginationMeta.previous) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (paginationMeta.next) {
      setPage(page + 1);
    }
  };

  const buttonStyle = {
    margin: '8px',
    padding: '8px 16px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    cursor: 'pointer',
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#b0bec5',
    cursor: 'not-allowed',
  };

  const fetchBookings = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/bookings`);
      setBookingData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/users/users`);
      console.log("Fetched users data:", response.data);
      setUsers(Array.isArray(response.data.results) ? response.data.results : []);
    } catch (err) {
      console.error('Error fetching users:', err);
    }
  };
  
  

  useEffect(() => {
    fetchEvents();
    fetchBookings();
    fetchUsers(); // Fetch users when component mounts
  }, []);

  const handleOpenCreateEventPopup = () => {
    setOpenCreateEventPopup(true);
  };

  const handleCloseCreateEventPopup = () => {
    setOpenCreateEventPopup(false);
  };

  const handleCreateEvent = async () => {
    try {
      const entryPrice = parseFloat(newEventData.entry_price);
      const payload = {
        name: newEventData.name,
        start_date: newEventData.start_date,
        end_date: newEventData.end_date,
        entry_price: entryPrice,
      };

      const response = await axios.post(`${backendUrl}/api/events/`, payload);

      fetchEvents();
      handleCloseCreateEventPopup();
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e, isCreating) => {
    const { name, value } = e.target;
    if (isCreating) {
      setNewEventData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setEditedEventData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleOpenDetailsDialog = (event) => {
    setSelectedEventDetails(event);
    setOpenDetailsDialog(true);
  };

  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
  };

  const handleOpenEditDialog = (event) => {
    setSelectedEvent(event);
    const editedData = { ...event };
    setEditedEventData(editedData);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveChanges = async () => {
    try {
      if (!selectedEvent) {
        console.error("No event selected for editing.");
        return;
      }

      const payload = { ...editedEventData };
      delete payload.event_image; // Ensure event_image is not sent

      const response = await axios.put(
        `${backendUrl}/api/events/${selectedEvent.id}/`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        fetchEvents();
        handleCloseEditDialog();
      } else {
        console.error("Failed to update event data. Unexpected status code:", response.status);
      }
    } catch (err) {
      console.error("Error updating event data:", err);
      if (err.response) {
        console.error("Server error message:", err.response.data);
      }
    }
  };

  const handleOpenBookingDialog = () => {
    setOpenBookingDialog(true);
  };

  const handleCloseBookingDialog = () => {
    setOpenBookingDialog(false);
  };

  const handleBookingChange = (e) => {
    const { name, value } = e.target;
    seteventBookingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSearchPhoneNumberChange = (e) => {
    setSearchPhoneNumber(e.target.value);
  };


  const handlePhoneInputChange = (event) => {
    const { value } = event.target;
    setPhoneQuery(value);
    
    if (Array.isArray(users)) {
      const filteredResults = users.filter(user =>
        user.phone_number?.toLowerCase().includes(value.toLowerCase())
      );
    
      const filteredPhoneNumbers = filteredResults.map(user => user.phone_number);
      setFilteredPhoneNumbers(filteredPhoneNumbers);
    } else {
      console.error('Users data is not an array or is empty:', users);
    }
  };
  
  const handlePhoneNumberSelection = (phoneNumber) => {
    setPhoneQuery(phoneNumber);
  
    const user = users.find(user => user.phone_number === phoneNumber);
    if (user) {
      setSelectedUser(user);
      setSelectedUserName(`${user.first_name} ${user.last_name}`);
      seteventBookingData((prevData) => ({
        ...prevData,
        user: user.id,
      }));
    }
  };
  
  const handleSearchUserByPhoneNumber = () => {
    if (Array.isArray(users) && users.length > 0) {
      const normalizedPhoneQuery = phoneQuery.trim().toLowerCase();
      const user = users.find(user => user.phone_number?.trim().toLowerCase() === normalizedPhoneQuery);
    
      if (user) {
        setSelectedUser(user);
        setSelectedUserName(`${user.first_name} ${user.last_name}`);
        seteventBookingData((prevData) => ({
          ...prevData,
          user: user.id,
        }));
      } else {
        alert('User not found.');
      }
    } else {
      console.error('Users data is not an array or is empty.');
    }
  };
  
  
  
  

  
  
  const handlePayOnline = async () => {
    if (!eventbookingData.event || !eventbookingData.user) {
      alert('Please select an event and user.');
      return;
    }
  
    const selectedEvent = eventData.find(event => event.name === eventbookingData.event);
    const selectedUser = users.find(user => user.id === eventbookingData.user);
  
    if (!selectedEvent || !selectedUser) {
      alert('Error: Invalid event or user selection.');
      return;
    }
  
    const userEmail = selectedUser.email; // Accessing the email property of selectedUser
    const amountInPaise = selectedEvent.entry_price * 100; // Assuming entry_price is in rupees
  
    const razorpayOptions = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      key_secret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
      amount: amountInPaise, // Amount in paise
      currency: 'INR',
      name: 'Event Booking',
      description: 'Event Booking',
      image: '', // Your logo URL
      order_id: '', // Leave empty for now
      handler: async function (response) {
        try {
          const bookingResponse = await axios.post(`${backendUrl}/api/event/booking/`, {
            event: selectedEvent.name,
            user: selectedUser.id,
          });
          console.log('Booking Response:', bookingResponse.data);
  
          const invoiceId = bookingResponse.data.invoice_id;
  
          await axios.put(`${backendUrl}/api/invoice/${invoiceId}`, {
             is_paid: true,
             payment_type: 'Cash'
          });
  
          alert('Booking successful!');
          fetchBookings();
          handleCloseBookingDialog();
        } catch (error) {
          console.error('Error making booking:', error);
          alert('Booking failed. Please try again.');
        }
      },
      prefill: {
        name: `${selectedUser.first_name} ${selectedUser.last_name}`, // Prefill user's name if available
        email: userEmail, // Prefill user's email
        contact: '9999999999', // Prefill user's contact number if available
      },
      theme: {
        color: '#f26d21', // Your theme color
      },
    };
  
    try {
      const razorpayInstance = new window.Razorpay(razorpayOptions);
      razorpayInstance.open();
    } catch (error) {
      console.error("Error handling payment:", error);
    }
  };
  

  const handleCash = async () => {
    if (!eventbookingData.event || !eventbookingData.user) {
      alert('Please select an event and user.');
      return;
    }
  
    const selectedEvent = eventData.find(event => event.name === eventbookingData.event);
    const selectedUser = users.find(user => user.id === eventbookingData.user);
  
    if (!selectedEvent || !selectedUser) {
      alert('Error: Invalid event or user selection.');
      return;
    }
  
    const userEmail = selectedUser.email; // Accessing the email property of selectedUser
    const amountInPaise = selectedEvent.entry_price * 100; // Assuming entry_price is in rupees
  
    try {
      // Make booking request
      const bookingResponse = await axios.post(`${backendUrl}/api/event/booking/`, {
        event: selectedEvent.name,
        user: selectedUser.id,
      });
      console.log('Booking Response:', bookingResponse.data);
  
      const invoiceId = bookingResponse.data.invoice_id;
  
      // Update invoice with payment_type as 'cash' and is_paid as true
      await axios.put(`${backendUrl}/api/invoice/${invoiceId}`, {
        is_paid: true,
        payment_type: 'Cash'
      });
  
      alert('Booking successful!');
      fetchBookings();
      handleCloseBookingDialog();
    } catch (error) {
      console.error('Error making booking:', error);
      alert('Booking failed. Please try again.');
    }
  };
  
  const handleUserSelection = (user) => {
    setSelectedUser(user);
    setSelectedUserName(`${user.first_name} ${user.last_name}`);
    seteventBookingData((prevData) => ({
      ...prevData,
      user: user.id,
    }));
  };
  
  

  return (
    <div>
      <h2>Events Information</h2>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
        <Button variant="contained" onClick={handleOpenBookingDialog} style={{margin:'10px'}}>
          Book Event
        </Button>
        <Button variant="contained" onClick={handleOpenCreateEventPopup} style={{margin:'10px'}}>
          Add Events
        </Button>
      </div>
      <Dialog open={openCreateEventPopup} onClose={handleCloseCreateEventPopup} maxWidth={false}
      PaperProps={{
        style: {
          width: '80vw', // Adjust the width as needed
          height: '90vh', // Adjust the height as needed
          margin: 'auto',
        },
      }}>
        <DialogTitle>Create Activity</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            Event Name
          </Typography>
          <TextField
            fullWidth
            label="Event Name"
            name="name"
            value={newEventData.name}
            onChange={(e) => handleChange(e, true)}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>
            Start Date
          </Typography>
          <TextField
            fullWidth
            label="Start Date"
            name="start_date"
            type="date"
            value={newEventData.start_date}
            onChange={(e) => handleChange(e, true)}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Typography variant="subtitle1" gutterBottom>
            End Date
          </Typography>
          <TextField
            fullWidth
            label="End Date"
            name="end_date"
            type="date"
            value={newEventData.end_date}
            onChange={(e) => handleChange(e, true)}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Typography variant="subtitle1" gutterBottom>
            Entry Price
          </Typography>
          <TextField
            fullWidth
            label="Entry Price"
            name="entry_price"
            value={newEventData.entry_price}
            onChange={(e) => handleChange(e, true)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseCreateEventPopup} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleCreateEvent} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Table>
        <TableHead style={{ backgroundColor: '#DCD2D2', fontWeight: 'bold' }}>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Event Name</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Entry Price</TableCell>
            {/* <TableCell style={{ fontWeight: 'bold' }}>Registered Peoples</TableCell> */}
            {/* <TableCell style={{ fontWeight: 'bold' }}>Maximum Participants</TableCell> */}
            <TableCell style={{ fontWeight: 'bold' }}>Start Date</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>End Date</TableCell>
            <TableCell  sx={{ fontWeight: 'bold', textAlign: 'right'}}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventData.map((event) => (
            <TableRow key={event.id}>
              <TableCell>{event.name}</TableCell>
              <TableCell>{event.entry_price || '-'}</TableCell>
              {/* <TableCell>{event.registered_peoples || 'null'}</TableCell>
              <TableCell>{event.maximum_participants || 'null'}</TableCell> */}
              <TableCell>
    {event.start_date 
        ? (() => {
            const date = new Date(event.start_date);
            const options = { day: '2-digit', month: 'short', year: 'numeric' };
            return date.toLocaleDateString('en-US', options); // Month Name Date, Year format
        })() 
        : '-'}
</TableCell>
<TableCell>
    {event.end_date 
        ? (() => {
            const date = new Date(event.end_date);
            const options = { day: '2-digit', month: 'short', year: 'numeric' };
            return date.toLocaleDateString('en-US', options); // Month Name Date, Year format
        })() 
        : '-'}
</TableCell>


              <TableCell>
                <div style={{ display: 'flex', justifyContent:'flex-end' }}>
                  <Button variant="contained" color="primary" onClick={() => handleOpenDetailsDialog(event)} sx={{ marginRight: '8px' }}>
                    Details
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => handleOpenEditDialog(event)}>
                    Edit
                  </Button>
                </div>
                <Menu>
                  <MenuItem>Enroll</MenuItem>
                  <MenuItem>Register</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button 
                    onClick={handlePreviousPage}
                    style={page <= 1 ? disabledButtonStyle : buttonStyle}
                    disabled={page <= 1}
                >
                    Previous
                </Button>
                <Button 
                    onClick={handleNextPage}
                    style={page >= totalPages ? disabledButtonStyle : buttonStyle}
                    
                >
                    Next
                </Button>
            </div>

      <Dialog
        open={openDetailsDialog}
        onClose={handleCloseDetailsDialog}
        maxWidth={false}
        PaperProps={{
          style: {
            width: '80vw', // Adjust the width as needed
            height: '90vh', // Adjust the height as needed
            margin: 'auto',
          },
        }}
      >
        <DialogContent>
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => setSelectedTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Details" sx={{ color: 'black', fontWeight: 'bold' }} value="details" />
            <Tab label="Enroll" sx={{ color: 'black', fontWeight: 'bold' }} value="enroll" />
            <Tab label="History" sx={{ color: 'black', fontWeight: 'bold' }} value="reserve" />
          </Tabs>
          {selectedTab === 'details' && (
            <div>
              <Typography 
    variant="h6" 
    sx={{ 
        fontWeight: 'bold', 
        textAlign: 'center',
        marginTop:'12px' 
    }}
>
    Event Details
</Typography>

              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Event Name</TableCell>
                      <TableCell>{selectedEventDetails ? selectedEventDetails.name : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Entry Price</TableCell>
                      <TableCell>{selectedEventDetails ? selectedEventDetails.entry_price : ''}</TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell>Registered Peoples</TableCell>
                      <TableCell>{selectedEventDetails ? selectedEventDetails.registered_peoples : 'Null'}</TableCell>
                    </TableRow> */}
                    {/* <TableRow>
                      <TableCell>Maximum Participants</TableCell>
                      <TableCell>{selectedEventDetails ? selectedEventDetails.maximum_participants : 'Null'}</TableCell>
                    </TableRow> */}
                    <TableRow>
    <TableCell>Start Date</TableCell>
    <TableCell>
        {selectedEventDetails && selectedEventDetails.start_date
            ? (() => {
                const date = new Date(selectedEventDetails.start_date);
                
                // Formatting date as Month Name Date, Year
                const options = { day: '2-digit', month: 'short', year: 'numeric' };
                return date.toLocaleDateString('en-US', options);
            })()
            : ''}
    </TableCell>
</TableRow>


<TableRow>
    <TableCell>End Date</TableCell>
    <TableCell>
        {selectedEventDetails && selectedEventDetails.end_date
            ? (() => {
                const date = new Date(selectedEventDetails.end_date);
                
                // Formatting date as Month Name Date, Year
                const options = { day: '2-digit', month: 'long', year: 'numeric' };
                return date.toLocaleDateString('en-US', options);
            })()
            : ''}
    </TableCell>
</TableRow>


                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          {selectedTab === 'enroll' && <div>{/* Enroll content */}</div>}
          {selectedTab === 'reserve' && (
  <div>
    <Typography variant="h6">Booking History</Typography>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{fontWeight:'bold'}}>User Email</TableCell>
            <TableCell style={{fontWeight:'bold'}}>Booking Date</TableCell>
            <TableCell style={{fontWeight:'bold'}}>Entry Fee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedEventDetails && selectedEventDetails.bookings.map((booking) => (
            <TableRow key={booking.booking_id}>
              <TableCell>{booking.user_email}</TableCell>
              <TableCell>{booking.booking_date}</TableCell>
              <TableCell>{booking.entry_fee}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
)}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetailsDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditDialog} onClose={handleCloseEditDialog} 
      maxWidth={false}
      PaperProps={{
        style: {
          width: '60vw', // Adjust the width as needed
          height: '90vh', // Adjust the height as needed
          margin: 'auto',
        },
      }}>
        <DialogTitle 
    sx={{ 
        fontWeight: 'bold', 
        textAlign: 'center' 
    }}
>
    Edit Event
</DialogTitle>

        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            Event Name
          </Typography>
          <TextField
            fullWidth
            placeholder="Event Name"
            name="name"
            value={editedEventData.name}
            onChange={(e) => handleChange(e, false)}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>
            Entry Price
          </Typography>
          <TextField
            fullWidth
            name="entry_price"
            value={editedEventData.entry_price}
            onChange={(e) => handleChange(e, false)}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>
            Start Date
          </Typography>
          <TextField
            fullWidth
            name="start_date"
            type="date"
            value={editedEventData.start_date}
            onChange={(e) => handleChange(e, false)}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>
            End Date
          </Typography>
          <TextField
            fullWidth
            name="end_date"
            type="date"
            value={editedEventData.end_date}
            onChange={(e) => handleChange(e, false)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveChanges} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openBookingDialog} onClose={handleCloseBookingDialog}>
        <DialogTitle>Book Event</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Event</InputLabel>
            <Select
              name="event"
              value={eventbookingData.event}
              onChange={handleBookingChange}
            >
              {eventData.map((event) => (
                <MenuItem key={event.id} value={event.name}>
                  {event.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ marginTop: '20px' }}>
            <TextField
              label="Search by Phone Number"
              variant="outlined"
              value={phoneQuery}
              onChange={handlePhoneInputChange}
              fullWidth
            />
            <Button onClick={handleSearchUserByPhoneNumber} color="primary" style={{ marginLeft: '10px' }}>
              Search User
            </Button>
            <div>
              {filteredPhoneNumbers.length > 0 && (
                <ul>
                  {filteredPhoneNumbers.map((phoneNumber, index) => (
                    <li key={index} onClick={() => handlePhoneNumberSelection(phoneNumber)}>
                      {phoneNumber}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <TextField
            label="User Name"
            variant="outlined"
            value={selectedUserName}
            fullWidth
            disabled
            style={{ marginTop: '10px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBookingDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePayOnline} color="primary">
            Book
          </Button>
          <Button onClick={handleCash} color="primary">
            Accept Cash
          </Button>
        </DialogActions>
      </Dialog>


    </div>
  );
};

export default Events;
