import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import SponsorSearch from '../SearchComponent/SponsorSearch';

const Sponsor = () => {
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [users, setUsers] = useState([]);
  const [activities, setActivities] = useState([]);
  const [events, setEvents] = useState([]);
  const [packages, setPackages] = useState([]);
  const [sponsorships, setSponsorships] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [amount, setAmount] = useState('');
  const [packageDetails, setPackageDetails] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const [sponsorshipsPage, setSponsorshipsPage] = useState(1);
const [sponsorshipsTotalPages, setSponsorshipsTotalPages] = useState(1);
const [sponsorshipsPaginationMeta, setSponsorshipsPaginationMeta] = useState({
  count: 0,
  next: null,
  previous: null,
});
const backendUrl = process.env.REACT_APP_BACKEND_URL;
useEffect(() => {
  const fetchData = async () => {
    try {
      const [
        usersResponse,
        activitiesResponse,
        eventsResponse,
        packagesResponse,
        sponsorshipsResponse
      ] = await Promise.all([
        axios.get(`${backendUrl}/api/users/users/`),
        axios.get(`${backendUrl}/api/activity/`),
        axios.get(`${backendUrl}/api/events/`),
        axios.get(`${backendUrl}/api/sponsorpackage/`),
        axios.get(`${backendUrl}/api/sponsor/?page=${sponsorshipsPage}`)
      ]);

      setUsers(usersResponse.data.results || usersResponse.data || []);
      setActivities(activitiesResponse.data.results || activitiesResponse.data || []);
      setEvents(eventsResponse.data.results || eventsResponse.data || []);
      setPackages(packagesResponse.data.results || packagesResponse.data || []);
      const sponsorshipsData = sponsorshipsResponse.data.results || [];
      setSponsorships(sponsorshipsData);
      setFilteredData(sponsorshipsData);
      setSponsorshipsTotalPages(Math.ceil(sponsorshipsResponse.data.count / 10)); // Assuming page size is 10
      setSponsorshipsPaginationMeta({
        count: sponsorshipsResponse.data.count,
        next: sponsorshipsResponse.data.next,
        previous: sponsorshipsResponse.data.previous,
      });

      console.log("Sponsorships Data:", sponsorshipsData);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, [sponsorshipsPage]); // Fetch data again when sponsorshipsPage changes


const handlePreviousPage = () => {
  if (sponsorshipsPaginationMeta.previous) {
    setSponsorshipsPage(prevPage => Math.max(prevPage - 1, 1));
  }
};

const handleNextPage = () => {
  if (sponsorshipsPaginationMeta.next) {
    setSponsorshipsPage(prevPage => prevPage + 1);
  }
};


const buttonStyle = {
  margin: '8px',
  padding: '8px 16px',
  backgroundColor: '#3f51b5',
  color: '#fff',
  cursor: 'pointer',
};

const disabledButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#b0bec5',
  cursor: 'not-allowed',
};

  const handleClickOpen = (content) => {
    setDialogContent(content);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDialogContent('');
    setSelectedUser('');
    setSelectedOption('');
    setAmount('');
    setPackageDetails('');
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleOptionChange = (event) => {
    const selectedId = event.target.value;
    setSelectedOption(selectedId);
    if (dialogContent === 'Sponsor a Package') {
      const selectedPackage = packages.find(pkg => pkg.id === selectedId);
      setPackageDetails(selectedPackage);
    }
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handlePay = () => {
    if (
      (dialogContent === 'Sponsor a Player' || dialogContent === 'Sponsor a Sport' || dialogContent === 'Sponsor an Event') &&
      (!selectedUser || !selectedOption || !amount)
    ) {
      alert('Please select all fields.');
      return;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      key_secret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
      amount: dialogContent === 'Sponsor a Package' ? packageDetails.package_amount * 100 : amount * 100,
      currency: 'INR',
      name: 'Your Company Name',
      description: `Payment for ${dialogContent.toLowerCase()}`,
      handler: async function (response) {
        let sponsorPayload = {
          sponsor: selectedUser,
          amount: dialogContent === 'Sponsor a Package' ? packageDetails.package_amount : amount,
          payment_id: response.razorpay_payment_id,
        };

        if (dialogContent === 'Sponsor a Player') {
          sponsorPayload.sponsor_receiver = selectedOption;
        } else if (dialogContent === 'Sponsor a Sport') {
          sponsorPayload.sponsered_sports = selectedOption;
        } else if (dialogContent === 'Sponsor an Event') {
          sponsorPayload.sponsored_events = selectedOption;
        } else if (dialogContent === 'Sponsor a Package') {
          sponsorPayload.sponsor_plan = selectedOption;
        }

        try {
          await axios.post(`${backendUrl}/api/sponsor/`, sponsorPayload);
          handleClose();
        } catch (error) {
          console.error('Error making sponsor request:', error);
        }
      },
      prefill: {
        name: 'Your Name',
        email: 'your.email@example.com',
        contact: '9999999999',
      },
      notes: {
        address: 'Corporate Office',
      },
      theme: {
        color: '#3399cc',
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  let dropdownOptions = [];
  switch (dialogContent) {
    case 'Sponsor a Player':
      dropdownOptions = users.map(user => ({ id: user.id, name: user.first_name }));
      break;
    case 'Sponsor a Sport':
      dropdownOptions = activities.map(activity => ({ id: activity.id, name: activity.activity_name }));
      break;
    case 'Sponsor an Event':
      dropdownOptions = events.map(event => ({ id: event.id, name: event.name }));
      break;
    case 'Sponsor a Package':
      dropdownOptions = packages.map(pkg => ({ id: pkg.id, name: pkg.package_name }));
      break;
    default:
      break;
  }


  const handleSearch = (searchParams) => {
    console.log("Search Parameters:", searchParams);
  
    const filtered = sponsorships.filter(sponsorship => {
      const sponsor = sponsorship.sponsor ? sponsorship.sponsor.toString().toLowerCase() : '';
      const sponsoredPlayer = sponsorship.sponsor_receiver ? sponsorship.sponsor_receiver.toString().toLowerCase() : '';
      const sponsoredSport = sponsorship.sponsered_sports ? sponsorship.sponsered_sports.toString().toLowerCase() : '';
      const sponsoredEvent = sponsorship.sponsored_events ? sponsorship.sponsored_events.toString().toLowerCase() : '';
      const sponsoredPackage = sponsorship.sponsor_plan ? sponsorship.sponsor_plan.toString().toLowerCase() : '';
      const sponsorshipDate = sponsorship.sponsorship_date ? new Date(sponsorship.sponsorship_date).toLocaleDateString() : '';
  
      return (
        (!searchParams.sponsor || sponsor.includes(searchParams.sponsor.toLowerCase())) &&
        (!searchParams.sponsoredPlayer || sponsoredPlayer.includes(searchParams.sponsoredPlayer.toLowerCase())) &&
        (!searchParams.sponsoredSport || sponsoredSport.includes(searchParams.sponsoredSport.toLowerCase())) &&
        (!searchParams.sponsoredEvent || sponsoredEvent.includes(searchParams.sponsoredEvent.toLowerCase())) &&
        (!searchParams.sponsoredPackage || sponsoredPackage.includes(searchParams.sponsoredPackage.toLowerCase())) &&
        (!searchParams.sponsorshipDate || sponsorshipDate.includes(searchParams.sponsorshipDate)) &&
        (!searchParams.amount || sponsorship.amount.toString().toLowerCase().includes(searchParams.amount.toLowerCase()))
      );
    });
  
    setFilteredData(filtered);
    console.log(filtered); // Log the filtered data array, not the setFilteredData function
  };
  
  


  
  
  
  
  
  return (
    <Box mt={1}>
      <h2>Sponsors Information</h2>
      <SponsorSearch  onSearch={handleSearch}/>
      <Grid container spacing={2} mt={4} justifyContent="center">
        <Grid item>
          <Button variant="contained" onClick={() => handleClickOpen('Sponsor a Player')}>
            Sponsor a Player
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => handleClickOpen('Sponsor a Sport')}>
            Sponsor a Sport
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => handleClickOpen('Sponsor an Event')}>
            Sponsor an Event
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => handleClickOpen('Sponsor a Package')}>
            Sponsor a Package
          </Button>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogContent}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Details about how to ${dialogContent.toLowerCase()}.`}
          </DialogContentText>
          <TextField
            select
            label="Select User"
            value={selectedUser}
            onChange={handleUserChange}
            fullWidth
            margin="normal"
          >
            {users.map(user => (
              <MenuItem key={user.id} value={user.id}>
                {user.first_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Select Option"
            value={selectedOption}
            onChange={handleOptionChange}
            fullWidth
            margin="normal"
          >
            {dropdownOptions.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          {(dialogContent !== 'Sponsor a Package') && (
            <TextField
              label="Amount"
              value={amount}
              onChange={handleAmountChange}
              fullWidth
              margin="normal"
              type="number"
            />
          )}
          {(dialogContent === 'Sponsor a Package') && packageDetails && (
            <DialogContentText>
              <strong>Package Amount:</strong> {packageDetails.package_amount} INR<br/>
              <strong>Package Contains:</strong> {packageDetails.package_contains}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='contained' color="primary">
            Close
          </Button>
          <Button onClick={handlePay} variant='contained' color="primary">
            Pay
          </Button>
        </DialogActions>
      </Dialog>

      <Table style={{marginTop:'20px'}}>
        <TableHead>
          <TableRow style={{ backgroundColor: '#DCD2D2', fontWeight: 'bold' }}>
            <TableCell  style={{ fontWeight: 'bold' }}>Sponsor Name</TableCell>
            <TableCell  style={{ fontWeight: 'bold' }}>Sponsored Player</TableCell>
            <TableCell  style={{ fontWeight: 'bold' }}>Sponsored Sport</TableCell>
            <TableCell  style={{ fontWeight: 'bold' }}>Sponsored Event</TableCell>
            <TableCell  style={{ fontWeight: 'bold' }}>Sponsored Package</TableCell>
            <TableCell  style={{ fontWeight: 'bold' }}>Sponsorship Date</TableCell>
            <TableCell  sx={{ fontWeight: 'bold', textAlign: 'right'}}>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((sponsorship) => {
            const sponsor = users.find(user => user.id === sponsorship.sponsor) || {};
            const sponsorReceiver = users.find(user => user.id === sponsorship.sponsor_receiver) || { first_name: 'null' };
            const sponsoredSport = activities.find(activity => activity.id === sponsorship.sponsered_sports) || { activity_name: 'null' };
            const sponsoredEvent = events.find(event => event.id === sponsorship.sponsored_events) || { name: 'null' };
            const sponsorPackage = packages.find(pkg => pkg.id === sponsorship.sponsor_plan) || { package_name: 'null' };
            
            return (
              <TableRow key={sponsorship.id}>
                <TableCell>{sponsor.first_name || 'null'}</TableCell>
                <TableCell>{sponsorReceiver.first_name}</TableCell>
                <TableCell>{sponsoredSport.activity_name}</TableCell>
                <TableCell>{sponsoredEvent.name}</TableCell>
                <TableCell>{sponsorPackage.package_name}</TableCell>
                <TableCell>
    {sponsorship.sponsorship_date 
        ? (() => {
            const date = new Date(sponsorship.sponsorship_date);
            const options = { day: '2-digit', month: 'long', year: 'short', hour: '2-digit', minute: '2-digit', hour12: true };
            return date.toLocaleString('en-US', options); // Format: Month Name Date, Year, HH:MM AM/PM
        })() 
        : '-'}
</TableCell>

                <TableCell>{sponsorship.amount}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {/* <div>
      <button
        onClick={handlePreviousPage}
        disabled={!sponsorshipsPaginationMeta.previous}
        style={buttonStyle}
      >
        Previous
      </button>
      <button
        onClick={handleNextPage}
        disabled={!sponsorshipsPaginationMeta.next}
        style={buttonStyle}
      >
        Next
      </button>
    </div> */}

    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button 
                    onClick={handlePreviousPage}
                    disabled={!sponsorshipsPaginationMeta.previous}
                    style={buttonStyle}
                >
                    Previous
                </Button>
                <Button 
                    onClick={handleNextPage}
                    disabled={!sponsorshipsPaginationMeta.next}
                    style={buttonStyle}
                    
                >
                    Next
                </Button>
            </div>
  
    </Box>
  );
};

export default Sponsor;
