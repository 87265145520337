import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Typography
} from '@mui/material';
import axios from 'axios';
import FinanceSearch from '../SearchComponent/FinanceSearch';

const Finance = () => {
  const [activityData, setActivityData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [openCreateActivityPopup, setOpenCreateActivityPopup] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [newActivityData, setNewActivityData] = useState({
    activity_name: '',
    current_hourly_fees: '',
    current_monthly_fees: '',
    max_team_size: '',
    max_time_limit: '',
    available_court: ''
  });
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [editedActivityData, setEditedActivityData] = useState({
    user: '',
    first_name: '',
    last_name: '',
    invoice_type: '',
    sports: '',
    amount: '',
    date_created: '',
    is_paid: '',
  });
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [selectedActivityDetails, setSelectedActivityDetails] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState('details'); // Set default tab to 'details'
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData(currentPage);
}, [currentPage]);


useEffect(() => {
  fetchData(page);
}, [page]);


const backendUrl = process.env.REACT_APP_BACKEND_URL;
const fetchData = async (page) => {
    try {
        const response = await axios.get(`${backendUrl}/api/invoice/?page=${page}`);
        console.log(response.data);
        
        setActivityData(response.data.results);
        setFilteredData(response.data.results);
        setTotalPages(Math.ceil(response.data.count / response.data.page_size));
        setPaginationMeta({
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous
        });
    } catch (error) {
        console.log(error);
    }
};

const handlePreviousPage = () => {
  if (page > 1) {
      setPage(page - 1);
  }
};

const handleNextPage = () => {
  setPage(page + 1);
};

const buttonStyle = {
  margin: '8px',
  padding: '8px 16px',
  backgroundColor: '#3f51b5',
  color: '#fff',
  cursor: 'pointer',
};

const disabledButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#b0bec5',
  cursor: 'not-allowed',
};


  const handleSearch = (searchParams) => {
    const filtered = activityData.filter(activity => {
      return (
        (!searchParams.firstName || activity.first_name.toLowerCase().includes(searchParams.firstName.toLowerCase())) &&
        (!searchParams.lastName || activity.last_name.toLowerCase().includes(searchParams.lastName.toLowerCase())) &&
        (!searchParams.userId || activity.users_id.toString().includes(searchParams.userId)) &&
        (!searchParams.telephoneNumber || activity.telephoneNumber?.toString().includes(searchParams.telephoneNumber)) &&
        (!searchParams.email || activity.email?.toLowerCase().includes(searchParams.email.toLowerCase())) &&
        (!searchParams.location || activity.location?.toLowerCase().includes(searchParams.location.toLowerCase())) &&
        (!searchParams.language || activity.language?.toLowerCase().includes(searchParams.language.toLowerCase())) &&
        (!searchParams.sportsName || activity.sports.toLowerCase().includes(searchParams.sportsName.toLowerCase())) &&
        (!searchParams.memberId || activity.memberId?.toString().includes(searchParams.memberId))
      );
    });
    setFilteredData(filtered);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedActivityData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleOpenCreateActivityPopup = () => {
    setOpenCreateActivityPopup(true);
  };

  const handleCloseCreateActivityPopup = () => {
    setOpenCreateActivityPopup(false);
  };

  const handleCreateActivity = async () => {
    try {
      await axios.post(`${backendUrl}/api/activity/`, newActivityData);
      fetchData();
      handleCloseCreateActivityPopup();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenEditDialog = (activity) => {
    setSelectedActivity(activity);
    setEditedActivityData({
      first_name: activity.first_name,
      last_name: activity.last_name,
      invoice_type: activity.invoice_type,
      sports: activity.sports,
      amount: activity.amount,
      date_created: activity.date_created,
      is_paid: activity.is_paid,
    });
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveChanges = async () => {
    try {
      await axios.put(`${backendUrl}/api/invoice/${selectedActivity.id}`, editedActivityData);
      fetchData();
      handleCloseEditDialog();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDetailsDialog = (activity) => {
    setSelectedActivityDetails(activity);
    setOpenDetailsDialog(true);
  };

  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
  };

  const handleEnrollRegisterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEnrollRegisterClose = () => {
    setAnchorEl(null);
  };

  console.log(selectedActivityDetails);

  const handlePayments = async (activity) => {
    try {
      console.log("handleChangePayments");
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        key_secret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
        amount: activity.amount * 100,
        currency: 'INR',
        name: 'Ishta',
        description: 'Testing Payment',
        handler: function (response) {
          console.log("Razorpay Response:", response); 
          const paymentMethod = response.method; 
          markPaymentAsPaid(activity, response, paymentMethod);

          // Log order_id and invoice_id to the console
          console.log("Order ID:", response.order_id);
          console.log("Invoice ID:", response.invoice_id);
        },
        prefill: {
          name: "vishwa",
          email: 'vishwavisu16102002@gmail.com',
          contact: '7558177141'
        },
        notes: {
          address: 'Razorpay corporate office'
        },
        theme: {
          color: "#3399cc"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    } catch (error) {
      console.error("Error handling payment:", error);
    }
  };

  const markPaymentAsPaid = async (activity, paymentResponse, paymentMethod) => {
    try {
      // Extract payment date
      const paymentDate = new Date();

      // Make a PUT request to update the is_paid status and payment details
      await axios.put(`${backendUrl}/api/invoice/${activity.id}`, {
        ...activity,
        is_paid: true,
        payment_type: paymentMethod, // Include payment type in the payload
        date_payed: paymentDate.toISOString()
      });
      // Refetch data or update the activity status in the local state if needed
      alert("Payment Successful"); 
      fetchData(); // Assuming fetchData fetches the activity data again
    } catch (error) {
      console.error("Error marking payment as paid:", error);
    }
  };

  return (
    <div>
     
      <h2>Finance Information</h2>
      <FinanceSearch onSearch={handleSearch} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
      </div>
      <Dialog open={openCreateActivityPopup} onClose={handleCloseCreateActivityPopup}>
        <DialogTitle>Create Activity</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Activity Name"
            name="activity_name"
            value={newActivityData.activity_name}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Hourly Fees"
            name="current_hourly_fees"
            value={newActivityData.current_hourly_fees}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Monthly Fees"
            name="current_monthly_fees"
            value={newActivityData.current_monthly_fees}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Max Team Size"
            name="max_team_size"
            value={newActivityData.max_team_size}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Max Time Limit"
            name="max_time_limit"
            value={newActivityData.max_time_limit}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Available Court"
            name="available_court"
            value={newActivityData}
            onChange={handleChange}
            margin="normal"
            />
            </DialogContent>
            <DialogActions>
            <Button variant="contained" onClick={handleCloseCreateActivityPopup} color="primary">
            Cancel
            </Button>
            <Button variant="contained" onClick={handleCreateActivity} color="primary">
            Create
            </Button>
            </DialogActions>
            </Dialog>
           
            <TableContainer>
            <Table>
      <TableHead style={{ backgroundColor: '#DCD2D2', fontWeight: 'bold' }}>
        <TableRow>
          <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Payment Type</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Sports</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Date Created</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Is Paid</TableCell>
          <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredData.map(activity => (
          <TableRow key={activity.id}>
            <TableCell>{activity.first_name}</TableCell>
            <TableCell>{activity.last_name}</TableCell>
            <TableCell>{activity.payment_type}</TableCell>
            <TableCell>{activity.sports}</TableCell>
            <TableCell>{activity.amount}</TableCell>
            <TableCell>
    {(() => {
        const date = new Date(activity.date_created);
        const day = date.getDate().toString().padStart(2, '0');
        const monthName = date.toLocaleString('en-US', { month: 'short' }); // Full month name
        const year = date.getFullYear();
        return `${monthName} ${day}, ${year}`;
    })()}
</TableCell>

            <TableCell>{activity.is_paid ? 'Paid' : 'Unpaid'}</TableCell>
            <TableCell>
              <div style={{ display: 'flex', justifyContent:'flex-end', alignItems: 'center' }}>
                {!activity.is_paid ? (
                  <Button variant="contained" color="primary" onClick={() => handlePayments(activity)} sx={{ marginRight: '8px' }}>Pay</Button>
                ) : (
                  <div style={{ minWidth: '65px' }} /> // Empty space to align with Pay button width
                )}
                <Button style={{ minWidth: '80px' }} variant="contained" color="primary" onClick={() => handleOpenDetailsDialog(activity)} sx={{ marginRight: '8px' }}>Details</Button>
                <Button style={{ minWidth: '80px' }} variant="contained" color="primary" onClick={() => handleOpenEditDialog(activity)}>Edit</Button>
              </div>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleEnrollRegisterClose}
              >
                <MenuItem onClick={() => handleEnrollRegisterClose()}>Enroll</MenuItem>
                <MenuItem onClick={() => handleEnrollRegisterClose()}>Register</MenuItem>
              </Menu>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button 
                    onClick={handlePreviousPage}
                    style={page <= 1 ? disabledButtonStyle : buttonStyle}
                    disabled={page <= 1}
                >
                    Previous
                </Button>
                <Button 
                    onClick={handleNextPage}
                    style={page >= totalPages ? disabledButtonStyle : buttonStyle}
                    
                >
                    Next
                </Button>
            </div>
  </TableContainer>
  <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
    <DialogTitle>Edit Finance</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        label="First Name"
        name="first_name"
        value={editedActivityData.first_name}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Last Name"
        name="last_name"
        value={editedActivityData.last_name}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Invoice Type"
        name="invoice_type"
        value={editedActivityData.invoice_type}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Max Team Size"
        name="sports"
        value={editedActivityData.sports}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Amount"
        name="amount"
        value={editedActivityData.amount}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Date Created"
        name="date_created"
        value={editedActivityData.date_created}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Is_Paid"
        name="is_paid"
        value={editedActivityData.is_paid}
        onChange={handleChange}
        margin="normal"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseEditDialog} color="primary">
        Cancel
      </Button>
      <Button onClick={handleSaveChanges} color="primary">
        Save Changes
      </Button>
    </DialogActions>
  </Dialog>
  <Dialog
    open={openDetailsDialog}
    onClose={handleCloseDetailsDialog}
    maxWidth={false}
    PaperProps={{
      style: {
        width: '80vw', // Adjust the width as needed
        height: '100vh', // Adjust the height as needed
        margin: 'auto',
      },
    }}
  >
    <DialogContent style={{ 
    
    overflowX: 'auto',
    width: '100%',
    height: '100%',


   }}>
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Details" sx={{ color: 'black', fontWeight: 'bold' }} value="details" />
        <Tab label="Enroll" sx={{ color: 'black', fontWeight: 'bold' }} value="enroll" />
        <Tab label="Reserve" sx={{ color: 'black', fontWeight: 'bold' }} value="reserve" />
      </Tabs>
      {selectedTab === 'details' && (
        <div>
          <Typography  variant="h6"  marginTop={'10px'} marginBottom={'20px'} display={'flex'} alignItems={'center'} style={{ fontWeight: 'bold' }} justifyContent={'center'}> Invoice  Details</Typography>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>User Id</TableCell>
                  <TableCell>{selectedActivityDetails ? selectedActivityDetails.users_id : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>{selectedActivityDetails ? selectedActivityDetails.first_name : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last Name</TableCell>
                  <TableCell>{selectedActivityDetails ? selectedActivityDetails.last_name : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Invoice Type</TableCell>
                  <TableCell>{selectedActivityDetails ? selectedActivityDetails.invoice_type : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sports</TableCell>
                  <TableCell>{selectedActivityDetails ? selectedActivityDetails.sports : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Amount</TableCell>
                  <TableCell>{selectedActivityDetails ? selectedActivityDetails.amount : ''}</TableCell>
                </TableRow>
                <TableRow>
    <TableCell>Date Created</TableCell>
    <TableCell>
        {selectedActivityDetails && selectedActivityDetails.date_created
            ? (() => {
                const date = new Date(selectedActivityDetails.date_created);
                const day = date.getDate().toString().padStart(2, '0');
                const monthName = date.toLocaleString('en-US', { month: 'short' }); // Full month name
                const year = date.getFullYear();
                return `${monthName} ${day}, ${year}`;
            })()
            : 'Null'}
    </TableCell>
</TableRow>

                <TableRow>
                  <TableCell>Is Paid</TableCell>
                  <TableCell>{selectedActivityDetails ? selectedActivityDetails.is_paid : ''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '20px' }}>
            <Button variant='contained' onClick={handleCloseDetailsDialog} color="primary">
              Exit
            </Button>
            <Button  variant='contained' onClick={() => handlePayments(selectedActivityDetails)}  color="primary">
              Accept Payment
            </Button>
            <Button  variant='contained' onClick={handleCloseDetailsDialog}  color="primary">
              Close
            </Button>
          </div>
        </div>
      )}
      {selectedTab === 'enroll' && (
        <div>
          {/* Enroll content */}
        </div>
      )}
      {selectedTab === 'reserve' && (
  <div>
    {/* Reserve content */}
  </div>
)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetailsDialog} variant='contained' color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Finance;


