import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import FinanceSearch from '../SearchComponent/FinanceSearch';
import Box from '@mui/material/Box';

const Members = () => {
    const[jobsData,setJobsData]=useState([]);
    const [openCreateJobPopup,SetOpenCreateJobPopup]=useState(false);
    const [selectedJobDetails, setSelectedJobDetails] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [selectedTab, setSelectedTab] = useState('details');
    const [selectedJob, setSelectedJob] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [reserve,SetReserve]=useState([]);
    const[invoices,setInvoices] = useState([]);
    const [paid,SetPaid]=useState([]);
    const[unpaid,SetUnPaid]=useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [newJobData, setNewJobData] = useState({
      "first_name": "",
      "last_name": "",
      "experiance_years": "",
      "expertise": "",
      "phone_number": "",
      "email": "",
      "address": ""
    });
    
    const [editedEventData, setEditedEventData] = useState({
      "first_name": "",
      "last_name": "",
      "experiance_years": "",
      "phone_number": "",
      "email": "",
      "address": "",
      "aadhar":"",
      "address2":"",
      "address3":"",
      "city":"",
      "pincode":"",
      "state":""


    });
    const [openPaymentMethodDialog, setOpenPaymentMethodDialog] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginationMeta, setPaginationMeta] = useState({
      count: 0,
      next: null,
      previous: null,
    });

    const getSelectedInvoice = (jobDetails) => {
      if (jobDetails && jobDetails.id) {
        return unpaid.find(invoice => invoice.id === jobDetails.id);
      } else {
        
        console.error("Invalid job details:", jobDetails);
        return undefined; 
      }
    };
    
    
    

    const handlePayBill = (invoice) => {
      // Handle paying the bill here
      // You can open the payment method selection dialog here
      setOpenPaymentMethodDialog(true);
      
      // Find the selected invoice from the unpaid invoices
      const selectedInvoice = getSelectedInvoice(invoice);
      setSelectedInvoice(invoice);
      
      // Log the selected invoice details to the console
      console.log("Selected Invoice:", selectedInvoice);
    };
    
    
    
    const handleClosePaymentMethodDialog = () => {
      setOpenPaymentMethodDialog(false);
    };
    
    const handlePaymentMethodChange = (event) => {
      setSelectedPaymentMethod(event.target.value);
    };
    
    const handlePayOnline = async () => {
      try {
       
        if (!selectedInvoice) {
          console.error("Selected invoice not found.");
          return;
        }
        
        // Construct the payment options for Razorpay
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          key_secret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
          amount: selectedInvoice.amount * 100, // Multiply by 100 to convert to paise
          currency: 'INR',
          name: 'Ishta',
          description: 'Testing Payment',
          handler: function (response) {
            console.log("Razorpay Response:", response); 
            const paymentMethod = response.method; 
            
            // alert(response.razorpay_payment_id); 
            
            
            console.log("Order ID:", response.order_id);
            console.log("Invoice ID:", response.invoice_id);
            
            // Make the PUT request to mark the invoice as paid
            markInvoiceAsPaid();
            handleUnPaidInvoice();
          },
          prefil: {
            name: "vishwa",
            email: 'vishwavisu16102002@gmail.com',
            contact: '7558177141'
          },
          notes: {
            address: 'Razorpay corporate office'
          },
          theme: {
            color: "#3399cc"
          }
        };
      
        
        var pay = new window.Razorpay(options);
        pay.open();
      } catch (error) {
        console.error("Error handling payment:", error);
      }
    };
    
    const markInvoiceAsPaid = async () => {
      try {
        // Make the PUT request to mark the invoice as paid
        const response = await axios.put(`${backendUrl}/api/invoice/${selectedInvoice.id}`, {
          is_paid: true,
          "payment_type": "Online",
        });
    
        // Check if the request was successful
        if (response.status === 200 || response.status === 201) {
          console.log("Invoice marked as paid successfully.");
          alert("payment successfully"); 
        } else {
          console.error("Failed to mark invoice as paid. Unexpected status code:", response.status);
        }
      } catch (error) {
        console.error("Error marking invoice as paid:", error);
      }
    };
    

    const offLinePayment = async (selectedPaymentMethod) => {
      try {
        // Make the PUT request to mark the invoice as paid
        const response = await axios.put(`${backendUrl}/api/invoice/${selectedInvoice.id}`, {
          is_paid: true,
          payment_type: selectedPaymentMethod, // Use the selected payment method here
        });
    
        // Check if the request was successful
        if (response.status === 200 || response.status === 201) {
          // Close the payment method dialog
          handleUnPaidInvoice();
          handlePaidInvoice();
          handleClosePaymentMethodDialog();
        } else {
          console.error("Failed to mark invoice as paid. Unexpected status code:", response.status);
        }
      } catch (error) {
        console.error("Error marking invoice as paid:", error);
      }
    };
    
    
    
    
    
    
    useEffect(() => {
      fetchJobs(page);
    }, [page]);
  
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
    const fetchJobs = async (page = 1) => { // Default to page 1 if none is provided
      try {
          const response = await axios.get(`${backendUrl}/api/users/users/?page=${page}`);
          console.log("API Response:", response.data); // Confirm response structure
          
          // Adjust based on how your API responds (check if data is wrapped in a results key)
          const jobData = response.data.results || response.data; // Handle cases with or without results wrapping
  
          // Check if jobData is an array and set state accordingly
          if (Array.isArray(jobData)) {
              console.log("Job data found:", jobData); // Log to verify the array of job data
              setJobsData(jobData);
              setFilteredData(jobData);
  
              // Set pagination meta based on API response
              setPaginationMeta({
                  count: response.data.count || jobData.length, // Total count of jobs
                  next: response.data.next,
                  previous: response.data.previous,
              });
  
              // Set total pages based on count and items per page
              setTotalPages(Math.ceil((response.data.count || jobData.length) / 10)); // Assuming 10 jobs per page
          } else {
              console.error("Unexpected response format:", response.data);
              setFilteredData([]);
          }
      } catch (error) {
          console.error("Error fetching jobs:", error);
      }
  };
  
    
    
  
    const handlePreviousPage = () => {
      if (paginationMeta.previous) {
        setPage(page - 1);
      }
    };
  
    const handleNextPage = () => {
      if (paginationMeta.next) {
        setPage(page + 1);
      }
    };
  
    
    const buttonStyle = {
      margin: '8px',
      padding: '8px 16px',
      backgroundColor: '#3f51b5',
      color: '#fff',
      cursor: 'pointer',
    };
    
    const disabledButtonStyle = {
      ...buttonStyle,
      backgroundColor: '#b0bec5',
      cursor: 'not-allowed',
    };
    
    const handleOpenCreateJobPopup = () => {
      SetOpenCreateJobPopup(true)
    };
  
    const handleCloseCreateJobPopup = () => {
      SetOpenCreateJobPopup(false);
    };
  
    const handleChange = (e, isCreating) => {
      const { name, value } = e.target;
      if (isCreating) {
        setNewJobData(prevData => ({
          ...prevData,
          [name]: value
        }));
      } 
      else {
        setEditedEventData(prevData => ({
          ...prevData,
          [name]: value
        }));
      }
      
    };
  
    useEffect(()=>{
            fetchJobs();
            // handleReserve();
    },[])

    useEffect(() => {
      console.log('Filtered Data Updated:', filteredData);
    }, [filteredData]);  // This will run every time filteredData updates
    
  
    const handleOpenDetailsDialog = (jobs) => {
      setSelectedJobDetails(jobs);
      setOpenDetailsDialog(true);
      console.log("Selected Job Details:", jobs.id);
      setSelectedJob(jobs);
    
      // Add a null check for jobs.id before calling handleReserve and handlePaidInvoice
      if (jobs.id) {
        handleReserve(jobs.id);
        handlePaidInvoice(jobs.id);
        handleUnPaidInvoice(jobs); // Pass job details object to fetch unpaid invoices
      }
    };
    
    
    
    
    
    
  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
  };
  
  
  const handleOpenEditDialog = (jobs) => {
  setSelectedJob(jobs);
  const editedData = { ...jobs };
  setEditedEventData(editedData);
  setOpenEditDialog(true); // Make sure this line is present
  handleReserve(); // Fetch reservation data
  };
  
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };
  
  const handleSaveChanges = async () => {
    try {
      // Check if selectedEvent is not null
      if (!selectedJob) {
        console.error("No event selected for editing.");
        return;
      }
  
      // Send PUT request to update event data
      const response = await axios.put(`${backendUrl}/api/users/update/${selectedJob.id}/`, editedEventData);
  
      // Check if the request was successful (status code 2xx)
      if (response.status === 200 || response.status === 201) {
        // Fetch updated event data after successful update
        fetchJobs();
        handleCloseEditDialog();
      } else {
        // Log error if response status code is not in the 2xx range
        console.error("Failed to update event data. Unexpected status code:", response.status);
      }
    } catch (err) {
      // Log detailed error message
      console.error("Error updating event data:", err);
  
      // Check if the error has a response and log the server error message
      if (err.response) {
        console.error("Server error message:", err.response.data);
      }
    }
  };

  const handleReserve=async()=>{
    try{
        const response = await axios.get(`${backendUrl}/api/users/reservations/${selectedJob.id}/`);
        SetReserve(response.data);
        console.log(response.data);
    }
    catch(err){
        console.log(err)
    }
  }

  const handlePaidInvoice = async () => {
    try {
      const url = `${backendUrl}/api/paid/${selectedJob.id}`;
      console.log("Request URL:", url); // Log the URL before making the request
  
      const response = await axios.get(url);
      console.log("Response:", response.data);
      SetPaid(response.data);
    } catch (err) {
      console.log("Error:", err);
    }
  };
  
  
  
  
  
  

  const handleUnPaidInvoice=async()=>{
    try {
      const url = `${backendUrl}/api/unpaid/${selectedJob.id}`;
      console.log("Request URL:", url); // Log the URL before making the request
  
      const response = await axios.get(url);
      console.log("UnPaidResponse:", response.data);
      SetUnPaid(response.data);
    } catch (err) {
      console.log("Error:", err);
    }
  }

  // useEffect(() => {
  //   if (selectedJobDetails && selectedJobDetails.id) {
  //     handleReserve();
  //     handlePaidInvoice();
  //     handleUnPaidInvoice();
  //   }

  // }, [selectedJobDetails]);

  useEffect(() => {
    if (selectedJob && selectedJob.id) {
      handleReserve(selectedJob.id);
      handlePaidInvoice(selectedJob.id);
      handleUnPaidInvoice(selectedJob.id); // Fetch unpaid invoices
    }
  }, [selectedJob]);


  const handleSearch = (searchParams) => {
    const filtered = jobsData.filter(job => {
        return (
            (!searchParams.firstName || job.first_name.toLowerCase().includes(searchParams.firstName.toLowerCase())) &&
            (!searchParams.lastName || job.last_name.toLowerCase().includes(searchParams.lastName.toLowerCase())) &&
            (!searchParams.userId || job.userId.toLowerCase().includes(searchParams.userId.toLowerCase())) &&
            (!searchParams.telephoneNumber || job.telephoneNumber.toLowerCase().includes(searchParams.telephoneNumber.toLowerCase())) &&
            (!searchParams.email || job.email.toLowerCase().includes(searchParams.email.toLowerCase())) &&
            (!searchParams.address || job.address.toLowerCase().includes(searchParams.address.toLowerCase()))
            // Add other conditions for filtering here
        );
    });
    setFilteredData(filtered);
    console.log('filter:',filteredData);
};

  
  
  return (
    <div>
        <h2>Members Information</h2>
        <FinanceSearch onSearch={handleSearch} />
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <Button variant="contained" color="primary" onClick={() => {
    window.location.href = '/UserCreate';
  }}>Add User</Button>
        </Box>
        
        
        <Table style={{marginTop:'10px'}}>
    <TableHead style={{ backgroundColor: '#DCD2D2', fontWeight: 'bold' }}>
        <TableRow>
        <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
        {/* <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell> */}
        <TableCell style={{ fontWeight: 'bold' }}>Email Id</TableCell>
        <TableCell style={{ fontWeight: 'bold' }}>Date Joined</TableCell>
        <TableCell style={{ fontWeight: 'bold' }}>Last Accessed</TableCell>
        <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
        {/* <TableCell style={{ fontWeight: 'bold' }}>Activites Name</TableCell> */}
        <TableCell sx={{ fontWeight: 'bold', textAlign: 'right'}}>Actions</TableCell>
        </TableRow>
    </TableHead>
    <TableBody>
  {filteredData.length > 0 ? (
    filteredData.map((jobs) => (
      <TableRow key={jobs.id}>
        <TableCell>{jobs.first_name || 'No First Name'}</TableCell>
        <TableCell>{jobs.email || 'No Email'}</TableCell>
        <TableCell>
  {jobs.date_joined
    ? (() => {
        const dateJoined = new Date(jobs.date_joined);
        const formattedDate = `${dateJoined.toLocaleString('en-US', { month: 'short' })}-${dateJoined.getDate().toString().padStart(2, '0')}-${dateJoined.getFullYear()}`;
        return formattedDate;
      })()
    : 'No Date'}
</TableCell>


<TableCell>
  {jobs.reservations && jobs.reservations.length > 0
    ? (() => {
        const reservationDate = new Date(jobs.reservations[jobs.reservations.length - 1].reservation_at);
        const formattedDate = `${reservationDate.toLocaleString('en-US', { month: 'short' })}-${reservationDate.getDate().toString().padStart(2, '0')}-${reservationDate.getFullYear()}`;
        return formattedDate;
      })()
    : 'No Reservations'}
</TableCell>


        <TableCell>Active</TableCell>
        <TableCell>
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px', margin: '10px' }}>
  <Button variant="contained" color="primary" onClick={() => handleOpenDetailsDialog(jobs)} style={{ marginRight: '10px' }}>
    Details
  </Button>
  <Button variant="contained" color="primary" onClick={() => handleOpenEditDialog(jobs)}>
    Edit
  </Button>
</div>

        </TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={6}>No data available</TableCell>
    </TableRow>
  )}
</TableBody>




  </Table>

  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button 
                    onClick={handlePreviousPage}
                    style={page <= 1 ? disabledButtonStyle : buttonStyle}
                    disabled={page <= 1}
                >
                    Previous
                </Button>
                <Button 
                    onClick={handleNextPage}
                    style={page >= totalPages ? disabledButtonStyle : buttonStyle}
                    
                >
                    Next
                </Button>
            </div>
  
  
  
  
  <Dialog
      open={openDetailsDialog}
      onClose={handleCloseDetailsDialog}
      maxWidth={false}
      PaperProps={{
        style: {
          width: '80vw', // Adjust the width as needed
          height: '90vh', // Adjust the height as needed
          margin: 'auto',
        },
      }}
    >
      {/* <DialogTitle>Activity Details</DialogTitle> */}
      <DialogContent
        style={{
          overflowX: 'auto',
          width: '100%',
          height: '100%',
        }}
      >
    
    <Tabs
      value={selectedTab}
      onChange={(event, newValue) => setSelectedTab(newValue)}
      indicatorColor="primary"
      textColor="primary"
      fontWeight='bolder'
      fontsize='29'
      centered
    >
     <Tab sx={{ fontWeight: 'bold', color: 'black' }} label="Details" value="details" />
<Tab sx={{ fontWeight: 'bold', color: 'black' }} label="Sports" value="sports" />
<Tab sx={{ fontWeight: 'bold', color: 'black' }} label="Reserve" value="reserve" />
<Tab sx={{ fontWeight: 'bold', color: 'black' }} label="Event History" value="event" />
<Tab sx={{ fontWeight: 'bold', color: 'black' }} label="Training" value="training" />
<Tab sx={{ fontWeight: 'bold', color: 'black' }} label="Coaches" value="coach" />
<Tab sx={{ fontWeight: 'bold', color: 'black' }} label="Paid" value="paid" />
<Tab sx={{ fontWeight: 'bold', color: 'black' }} label="Unpaid" value="unpaid" />

      
      
    
    </Tabs>
    {selectedTab === 'details' && (
  <div>
    <Typography variant="h6" marginTop={'10px'} marginBottom={'20px'} display={'flex'} alignItems={'center'} style={{ fontWeight: 'bold' }} justifyContent={'center'}>Members Details</Typography>
    <TableContainer>
  <Table>
    <TableBody>
      <TableRow>
        <TableCell>First Name</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.first_name : ''}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Last Name</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.last_name : ''}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Email</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.email : 'Null'}</TableCell>
      </TableRow>
      <TableRow>
  <TableCell>Date Joined</TableCell>
  <TableCell>
    {selectedJobDetails && selectedJobDetails.date_joined
      ? (() => {
          const dateJoined = new Date(selectedJobDetails.date_joined);
          const formattedDate = `${dateJoined.toLocaleString('en-US', { month: 'short' })}-${dateJoined.getDate().toString().padStart(2, '0')}-${dateJoined.getFullYear()}`;
          return formattedDate;
        })()
      : 'Null'}
  </TableCell>
</TableRow>

<TableRow>
  <TableCell>Last Accessed</TableCell>
  <TableCell>
    {selectedJobDetails && selectedJobDetails.reservations && selectedJobDetails.reservations.length > 0
      ? (() => {
          const lastReservation = new Date(selectedJobDetails.reservations[selectedJobDetails.reservations.length - 1].reservation_at);
          const formattedDate = `${lastReservation.toLocaleString('en-US', { month: 'short' })}-${lastReservation.getDate().toString().padStart(2, '0')}-${lastReservation.getFullYear()}`;
          return formattedDate;
        })()
      : 'Null'}
  </TableCell>
</TableRow>


  
     
      <TableRow>
        <TableCell>Status</TableCell>
        <TableCell>Is Active</TableCell>
      </TableRow>
     
    </TableBody>
  </Table>
  </TableContainer>
  
  </div>
  )}
  
  {selectedTab === 'sports' && (
  <div>
    <Typography variant="h6" marginTop={'10px'} marginBottom={'20px'} display={'flex'} alignItems={'center'} justifyContent={'center'} style={{ fontWeight: 'bold' }}>
      Reservation History
    </Typography>
    <Table style={{ marginTop: '5px' }}>
      <TableHead>
        <TableRow>
          <TableCell style={{ fontWeight: 'bold' }}>Reservation ID</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Sports</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Price</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Reservation Date</TableCell>
          {/* <TableCell style={{ fontWeight: 'bold' }}>Payment Status</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {reserve.map(reservation => (
          <TableRow key={reservation.reservation_id}>
            <TableCell>{reservation.reservation_id}</TableCell>
            <TableCell>{reservation.activity_name}</TableCell>
            <TableCell>₹{reservation.price}</TableCell>
            <TableCell>
  {reservation && reservation.reservation_date
    ? (() => {
        const date = new Date(reservation.reservation_date);

        // Formatting date as MonthName-Day-Year
        const month = date.toLocaleString('en-US', { month: 'short' });
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      })()
    : 'N/A'}
</TableCell>



            {/* <TableCell>
                    {invoices && invoices.length > 0 ? (
                      invoices.map(invoice => (
                        <div key={invoice.id}>
                          {invoice.is_paid ? 'Paid' : 'Not Paid'}
                        </div>
                      ))
                    ) : (
                      'Not Paid'
                    )}
                  </TableCell> */}

          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
)}

    {selectedTab === 'reserve' && (
  <div>
    <Typography variant="h6" marginTop={'10px'} marginBottom={'20px'} display={'flex'} alignItems={'center'} justifyContent={'center'} style={{ fontWeight: 'bold' }}>Reservation History</Typography>
    <Table style={{ marginTop: '5px' }}>
      <TableHead>
        <TableRow>
          <TableCell  style={{fontWeight:'bold'}}>Reservation ID</TableCell>
          <TableCell  style={{fontWeight:'bold'}}>Sports</TableCell>
          <TableCell  style={{fontWeight:'bold'}}>Price</TableCell>
          <TableCell  style={{fontWeight:'bold'}}>Reservation Date</TableCell>
          
          {/* <TableCell  style={{fontWeight:'bold'}}>Payment Status</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {reserve.map(reservation => (
          <TableRow key={reservation.reservation_id}>
            <TableCell>{reservation.reservation_id}</TableCell>
            <TableCell>{reservation.activity_name}</TableCell>
            <TableCell>₹{reservation.price}</TableCell>
            <TableCell>
  {reservation && reservation.reservation_date
    ? (() => {
        const date = new Date(reservation.reservation_date);

        // Formatting date as MonthName-Day-Year
        const month = date.toLocaleString('en-US', { month: 'short' });
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      })()
    : 'N/A'}
</TableCell>


            
            {/* <TableCell>{reservation.is_paid ? 'Paid' : 'Not Paid'}</TableCell> */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
)}
{selectedTab === 'event' && (
  <div>
    <Typography variant="h6" marginTop={'10px'} marginBottom={'20px'} display={'flex'} alignItems={'center'} justifyContent={'center'} style={{ fontWeight: 'bold' }}>
      Event History
    </Typography>
    <Table style={{ marginTop: '5px' }}>
      <TableHead>
        <TableRow>
          <TableCell  style={{fontWeight:'bold'}}>Entry Fee</TableCell>
          <TableCell  style={{fontWeight:'bold'}}>Booking ID</TableCell>
          <TableCell  style={{fontWeight:'bold'}}>Event Name</TableCell>
          <TableCell  style={{fontWeight:'bold'}}>Booking Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {selectedJobDetails.events.map(event => (
          <TableRow key={event.booking_id}>
            <TableCell>{event.entry_fee}</TableCell>
            <TableCell>{event.booking_id}</TableCell>
            <TableCell>{event.event_name}</TableCell>
            <TableCell>
  {event.booking_date
    ? (() => {
        const date = new Date(event.booking_date);
        
        // Formatting date as MonthName-Day-Year
        const month = date.toLocaleString('en-US', { month: 'short' });
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      })()
    : 'N/A'}
</TableCell>


          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
)}

{selectedTab === 'training' && (
  <div>
    <Typography variant="h6" marginTop={'10px'} marginBottom={'20px'} display={'flex'} alignItems={'center'} justifyContent={'center'} style={{ fontWeight: 'bold' }}>
      Training Details
    </Typography>
    <Table style={{ marginTop: '5px' }}>
      <TableHead>
        <TableRow>
          <TableCell style={{fontWeight:'bold'}}>Plan Name</TableCell>
          {/* <TableCell style={{fontWeight:'bold'}}>Event Type</TableCell> */}
          <TableCell style={{fontWeight:'bold'}}>Start Date</TableCell>
          <TableCell style={{fontWeight:'bold'}}>End Date</TableCell>
          <TableCell style={{fontWeight:'bold'}}>Location</TableCell>
          <TableCell style={{fontWeight:'bold'}}>Fees</TableCell>
          {/* <TableCell style={{fontWeight:'bold'}}>Notes</TableCell> */}
          {/* <TableCell style={{fontWeight:'bold'}}>Members Enrolled</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {selectedJobDetails.trainings.map(training => (
          <TableRow key={training.id}>
            <TableCell>{training.plan_name}</TableCell>
            {/* <TableCell>{training.event_type}</TableCell> */}
            <TableCell>
  {training.start_date
    ? (() => {
        const date = new Date(training.start_date);
        
        // Formatting date as MonthName-Day-Year
        const month = date.toLocaleString('en-US', { month: 'short' });
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      })()
    : 'N/A'}
</TableCell>


<TableCell>
  {training.end_date
    ? (() => {
        const date = new Date(training.end_date);
        
        // Formatting date as MonthName-Day-Year
        const month = date.toLocaleString('en-US', { month: 'short' });
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      })()
    : 'N/A'}
</TableCell>


            <TableCell>{training.location}</TableCell>
            <TableCell>₹{training.fees}</TableCell>
            <TableCell>{training.notes}</TableCell>
            {/* <TableCell>{training.members_enrolled}</TableCell> */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
)}


{selectedTab === 'coach' && (
  <div>
    <Typography variant="h6" marginTop={'10px'} marginBottom={'20px'} display={'flex'} alignItems={'center'} justifyContent={'center'} style={{ fontWeight: 'bold' }}>
      Coach History
    </Typography>
    <Table style={{ marginTop: '5px' }}>
      <TableHead>
        <TableRow>
          <TableCell  style={{fontWeight:'bold'}}>Coach First Name</TableCell>
          <TableCell  style={{fontWeight:'bold'}}>Coach Last Name</TableCell>
          <TableCell  style={{fontWeight:'bold'}}>Booked At</TableCell>
          {/* <TableCell  style={{fontWeight:'bold'}}>Fees Paid</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {selectedJobDetails.coaches.map(coach => (
          <TableRow key={coach.id}>
            <TableCell>{coach.coach_first_name}</TableCell>
            <TableCell>{coach.coach_last_name}</TableCell>
            <TableCell>
  {coach.booked_at
    ? (() => {
        const date = new Date(coach.booked_at);

        // Formatting date as MonthName-Day-Year
        const month = date.toLocaleString('en-US', { month: 'short' });
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      })()
    : 'N/A'}
</TableCell>


            {/* <TableCell>{coach.fees_paid ? 'Yes' : 'No'}</TableCell> */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
)}


          
{selectedTab === 'paid' && (
  <div>
    <Typography variant="h6" marginTop={'10px'} marginBottom={'20px'} display={'flex'} alignItems={'center'} justifyContent={'center'} style={{ fontWeight: 'bold' }}>Paid Invoices</Typography>
    <Table style={{ marginTop: '5px' }}>
      <TableHead>
        <TableRow>
          <TableCell style={{fontWeight:'bold'}}>Invoice Type</TableCell>
          <TableCell style={{fontWeight:'bold'}}>Sports</TableCell>
          <TableCell style={{fontWeight:'bold'}}>Price</TableCell>
          <TableCell style={{fontWeight:'bold'}}>Date Created</TableCell>
          <TableCell style={{fontWeight:'bold'}}>Is Paid</TableCell>
          {/* <TableCell style={{fontWeight:'bold'}}>Payment Type</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {paid.map(reservation => (
          <TableRow key={reservation.reservation_id}>
            <TableCell>{reservation.invoice_type}</TableCell>
            <TableCell>{reservation.sports}</TableCell>
            <TableCell>₹{reservation.amount}</TableCell>
            <TableCell>
  {reservation && reservation.date_created
    ? (() => {
        const date = new Date(reservation.date_created);

        // Formatting date as MonthName-Day-Year
        const month = date.toLocaleString('en-US', { month: 'short' });
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      })()
    : 'N/A'}
</TableCell>


            
            <TableCell>{reservation.is_paid ? 'Paid' : 'Not Paid'}</TableCell>
            {/* <TableCell>{reservation.payment_type}</TableCell> */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
)}



{selectedTab === 'unpaid' && (
  <div>
    <Typography variant="h6" marginTop={'10px'} marginBottom={'20px'} display={'flex'} alignItems={'center'} justifyContent={'center'} style={{ fontWeight: 'bold' }}>UnPaid Invoices</Typography>
    <Table style={{ marginTop: '5px' }}>
      <TableHead>
        <TableRow>
          <TableCell style={{fontWeight:'bold'}}>Invoice Type</TableCell>
          <TableCell style={{fontWeight:'bold'}}>Sports</TableCell>
          <TableCell style={{fontWeight:'bold'}}>Price</TableCell>
          <TableCell style={{fontWeight:'bold'}}>Date Created</TableCell>
          <TableCell style={{fontWeight:'bold'}}>Is Paid</TableCell>
          {/* <TableCell style={{fontWeight:'bold'}}>Payment Type</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {unpaid.map(reservation => (
          <TableRow key={reservation.reservation_id}>
            <TableCell>{reservation.invoice_type}</TableCell>
            <TableCell>{reservation.sports}</TableCell>
            <TableCell>₹{reservation.amount}</TableCell>
            <TableCell>
  {reservation && reservation.date_created
    ? (() => {
        const date = new Date(reservation.date_created);

        // Formatting date as MonthName-Day-Year
        const month = date.toLocaleString('en-US', { month: 'short' }); // Get abbreviated month name
        const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero
        const year = date.getFullYear(); // Get full year
        return `${month}-${day}-${year}`; // Combine into desired format
      })()
    : 'N/A'}
</TableCell>

            
            <TableCell>{reservation.is_paid ? 'Paid' : 'Not Paid'}</TableCell>
            {/* <TableCell>{reservation.payment_type}</TableCell> */}
            <TableCell><Button onClick={() => handlePayBill(reservation)}>Pay Bill</Button>
</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>

    <Dialog open={openPaymentMethodDialog} onClose={handleClosePaymentMethodDialog}>
      <DialogTitle>Select Payment Method</DialogTitle>
      <DialogContent>
        <FormControl>
          {/* <InputLabel id="payment-method-label">Payment Method</InputLabel> */}

          <Typography id="payment-method-label">Payment Method</Typography>
          <Select
            labelId="payment-method-label"
            id="payment-method-select"
            value={selectedPaymentMethod}
            onChange={handlePaymentMethodChange}
          >
            <MenuItem value="cash">Cash</MenuItem>
            <MenuItem value="card">Card</MenuItem>
            <MenuItem value="online">Online</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClosePaymentMethodDialog}>Cancel</Button>
        <Button 
  onClick={() => offLinePayment(selectedPaymentMethod)}>
  Pay Offline
</Button>

        <Button 
  onClick={() => handlePayOnline(selectedInvoice.id)}
  disabled={selectedPaymentMethod !== 'online'}
>
  Pay Online
</Button>


      </DialogActions>
    </Dialog>
  </div>
)}



  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDetailsDialog} color="primary" variant="contained" style={{display:'flex',justifyContent:'flex-end'}}>
      Close
    </Button>
  </DialogActions>
  </Dialog>
  
  
  <Dialog open={openEditDialog} onClose={handleCloseEditDialog}
  
  maxWidth={false}
  PaperProps={{
    style: {
      width: '60vw', // Adjust the width as needed
      height: '90vh', // Adjust the height as needed
      margin: 'auto',
    },
  }}
  
  >
        <DialogTitle 
    sx={{ 
        fontWeight: 'bold', 
        textAlign: 'center' 
    }}
>
    Edit Members
</DialogTitle>

        <DialogContent>
        <Typography variant="subtitle1" gutterBottom>First Name</Typography>
          <TextField
            fullWidth
            // label="Event Name"
            placeholder='First Name'
            name="first_name"
            value={editedEventData.first_name}
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>Last Name</Typography>
          <TextField
            fullWidth
            // label="HEntry"
            name="last_name"
            value={editedEventData.last_name}
            onChange={handleChange}
            margin="normal"
          />
  
  {/* <Typography variant="subtitle1" gutterBottom>Registered Peoples</Typography>
          <TextField
            fullWidth
            // label="Monthly Fees"
            name="registered_peoples"
            value={editedEventData.registered_peoples}
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>Maximum Participants</Typography>
          <TextField
            fullWidth
            // label="Max Team Size"
            name="maximum_participants"
            value={editedEventData.maximum_participants}
            onChange={handleChange}
            margin="normal"
          /> */}
  
  <Typography variant="subtitle1" gutterBottom>Experiance Years</Typography>
          <TextField
            fullWidth
            // label="Max Time Limit"
            name="experiance_years"
            value={editedEventData.experiance_years}
            
            onChange={handleChange}
            margin="normal"
          />
     <Typography variant="subtitle1" gutterBottom>Address </Typography>
  <TextField
            fullWidth
            // label="Available Court"
            name="address"
            value={editedEventData.address}
            
            onChange={handleChange}
            margin="normal"
          />
         
           <Typography variant="subtitle1" gutterBottom>Address2 </Typography>
          <TextField
            fullWidth
            // label="Available Court"
            name="address2"
            value={editedEventData.address2}
        
            onChange={handleChange}
            margin="normal"
          />

<Typography variant="subtitle1" gutterBottom>Address3 </Typography>
          <TextField
            fullWidth
            // label="Available Court"
            name="address3"
            value={editedEventData.address3}
        
            onChange={handleChange}
            margin="normal"
          />

<Typography variant="subtitle1" gutterBottom>City</Typography>
          <TextField
            fullWidth
            // label="Available Court"
            name="city"
            value={editedEventData.city}
        
            onChange={handleChange}
            margin="normal"
          />

<Typography variant="subtitle1" gutterBottom>Pin Code</Typography>
          <TextField
            fullWidth
            // label="Available Court"
            name="pincode"
            value={editedEventData.pincode}
        
            onChange={handleChange}
            margin="normal"
          />

<Typography variant="subtitle1" gutterBottom>State </Typography>
          <TextField
            fullWidth
            // label="Available Court"
            name="state"
            value={editedEventData.state}
        
            onChange={handleChange}
            margin="normal"
          />

<Typography variant="subtitle1" gutterBottom>Aadhar </Typography>
          <TextField
            fullWidth
            // label="Available Court"
            name="aadhar"
            value={editedEventData.aadhar}
        
            onChange={handleChange}
            margin="normal"
          />
   <Typography variant="subtitle1" gutterBottom>Phone Number </Typography>
  <TextField
            fullWidth
            // label="Available Court"
            name="phone_number"
            value={editedEventData.phone_number}
            
            onChange={handleChange}
            margin="normal"
          />
   <Typography variant="subtitle1" gutterBottom>Email </Typography>
  <TextField
            fullWidth
            // label="Available Court"
            name="email"
            value={editedEventData.email}
            
            onChange={handleChange}
            margin="normal"
          />
  
        </DialogContent>
        <DialogActions>
          <Button  onClick={handleCloseEditDialog}  variant="contained" color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveChanges} variant="contained"   color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
  
    </div>
  )
  }

export default Members