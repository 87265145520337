import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, Checkbox, FormControlLabel, Box, CircularProgress } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleLogin = async () => {
    setError('');
    setLoading(true);
    try {
      const response = await axios.post(`${backendUrl}/api/users/adminlogin/`, {
        email,
        password,
      });

      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem('token', token);
        navigate('/home');
      } else {
        setError('Invalid credentials or network error. Please try again.');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const data = error.response.data;
        if (data.email && data.password) {
          setError(`${data.email[0]} ${data.password[0]}`);
        } else if (data.email) {
          setError(data.email[0]);
        } else if (data.password) {
          setError(data.password[0]);
        } else {
          setError('An error occurred. Please try again later.');
        }
      } else {
        setError('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" marginTop="70px">
      <Box mt={4} mx="auto" p={3} border={1} borderRadius={5} maxWidth="400px" marginTop="19px">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom align="left" style={{ marginBottom: '20px' }}>
            Sign In
          </Typography>
          <Typography variant="body2">
            {/* <NavLink to="/mailScreen">Forgot Password?</NavLink> */}
          </Typography>
        </Box>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              label="Email"
              variant="outlined"
              placeholder="Enter your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!error}
              helperText={error.includes('email') ? error : ''}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!error}
              helperText={error.includes('password') ? error : ''}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={togglePasswordVisibility}
                  checked={showPassword}
                  color="primary"
                  disabled={loading}
                />
              }
              label={showPassword ? 'Hide Password' : 'Show Password'}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel control={<Checkbox />} label="Keep me signed in" />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleLogin}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
            </Button>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography color="error" align="center">
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Login;
