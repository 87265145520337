import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Menu,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from '@mui/material';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import FinanceSearch from '../SearchComponent/FinanceSearch';

const Coaches = () => {
  const[jobsData,setJobsData]=useState([]);
  const [openCreateJobPopup,SetOpenCreateJobPopup]=useState(false);
  const [usersData, setUsersData] = useState([]);
  const [coachesData, setCoachesData] = useState([]);
  const [selectedJobDetails, setSelectedJobDetails] = useState(null);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [selectedTab, setSelectedTab] = useState('details');
  const [selectedJob, setSelectedJob] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [phoneSearch, setPhoneSearch] = useState("");
  const [newJobData, setNewJobData] = useState({
    "first_name": "",
    "last_name": "",
    "experiance_years": "",
    "expertise": "",
    "coach_fees":"",
    "phone_number": "",
    "email": "",
    "address": ""
  });
  
  
  const [editedEventData, setEditedEventData] = useState({
    "first_name": "",
    "last_name": "",
    "experiance_years": "",
    "expertise": "",
    "coach_fees":"",
    "phone_number": "",
    "email": "",
    "address": ""
  });
  
  const [assignCoachingData, setAssignCoachingData] = useState({
    user: "",
    coach: ""
  });
  const [openAssignCoachingPopup, setOpenAssignCoachingPopup] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://myishta.com/api/users/users/");
      if (response.data && Array.isArray(response.data.results)) {
        console.log("Fetched users data:", response.data.results);
        setUsersData(response.data.results); // Store user data
        setFilteredUsers(response.data.results); // Initialize filtered users
      } else {
        console.error("Expected response.data to be an array, but got:", response.data);
        setUsersData([]); // Reset to empty if the structure is not correct
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  
  



  const fetchCoaches = async () => {
    try {
      const response = await axios.get("https://myishta.com/api/coaches/"); // Update this URL based on your API
      if (response.data && Array.isArray(response.data)) {
        console.log("Fetched coaches data:", response.data);
        setCoachesData(response.data); // Store coach data
      } else {
        console.error("Expected response.data to be an array, but got:", response.data);
        setCoachesData([]); // Reset to empty if the structure is not correct
      }
    } catch (error) {
      console.error("Error fetching coaches:", error);
    }
  };
  

  

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [paginationMeta, setPaginationMeta] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  useEffect(() => {
    fetchJobs(page);
  }, [page]);

  const fetchJobs = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/coaches/?page=${page}`);
      const coaches = response.data;
  
      console.log("Fetched coaches data:", coaches); // Check the structure here
  
      // Ensure coachesData is always set to an array
      setCoachesData(Array.isArray(coaches.results) ? coaches.results : []);
      setFilteredData(Array.isArray(coaches.results) ? coaches.results : []);
      setTotalPages(Math.ceil(coaches.count / 10)); 
      setPaginationMeta({
        count: coaches.count,
        next: coaches.next,
        previous: coaches.previous,
      });
    } catch (error) {
      console.error("Error fetching coaches:", error);
    }
  };
  

  const handlePreviousPage = () => {
    if (paginationMeta.previous) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (paginationMeta.next) {
      setPage(page + 1);
    }
  };

  const buttonStyle = {
    margin: '8px',
    padding: '8px 16px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    cursor: 'pointer',
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#b0bec5',
    cursor: 'not-allowed',
  };

  const handleAssignCoaching = async () => {
    try {
      const payload = {
        user: assignCoachingData.user,
        coach: assignCoachingData.coach
      };
      console.log(payload);
      const response = await axios.post(`${backendUrl}/api/coaches/booking`, payload);
      
      // Handle successful assignment
      fetchJobs();
      handleCloseAssignCoachingPopup();
  
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenAssignCoachingPopup = () => {
    setOpenAssignCoachingPopup(true);
  };

  const handleCloseAssignCoachingPopup = () => {
    setOpenAssignCoachingPopup(false);
    setPhoneSearch(""); // Clear the search input
    setFilteredUsers([]); // Clear the filtered users
    setSelectedUser(null); // Clear selected user
  };


  const handleCreateJobs = async () => {
    try {
      const payload = {
        first_name: newJobData.first_name,
        last_name: newJobData.last_name,
        experiance_years: newJobData.experiance_years,
        expertise: newJobData.expertise,
        phone_number: newJobData.phone_number,
        email: newJobData.email,
        address: newJobData.address
      };
  
      const response = await axios.post(`${backendUrl}/api/coaches/`, payload);
      
      // Handle successful creation
      fetchJobs();
      handleCloseCreateJobPopup();
  
    } catch (err) {
      console.log(err);
    }
  };
  
  const handleOpenCreateJobPopup = () => {
    SetOpenCreateJobPopup(true)
  };

  const handleCloseCreateJobPopup = () => {
    SetOpenCreateJobPopup(false);
  };

  const handleChange = (e, isCreating, isAssigning) => {
    const { name, value } = e.target;
    if (isCreating) {
      setNewJobData(prevData => ({
        ...prevData,
        [name]: value
      }));
    } else if (isAssigning) {
      setAssignCoachingData(prevData => ({
        ...prevData,
        [name]: value
      }));
    } else {
      setEditedEventData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  useEffect(()=>{
          fetchJobs();
          fetchUsers();
    fetchCoaches();
  },[])

  const handleOpenDetailsDialog = (jobs) => {
    setSelectedJobDetails(jobs);
    setOpenDetailsDialog(true);
    console.log("Selected Job Details:", jobs);
  };
  
const handleCloseDetailsDialog = () => {
  setOpenDetailsDialog(false);
};


const handleOpenEditDialog = (jobs) => {
setSelectedJob(jobs);
const editedData = { ...jobs };
setEditedEventData(editedData);
setOpenEditDialog(true); // Make sure this line is present
};

const handleCloseEditDialog = () => {
  setOpenEditDialog(false);
};

const handleSaveChanges = async () => {
  try {
    // Check if selectedEvent is not null
    if (!selectedJob) {
      console.error("No event selected for editing.");
      return;
    }

    // Send PUT request to update event data
    const response = await axios.put(`${backendUrl}/api/coaches/${selectedJob.id}/`, editedEventData);

    // Check if the request was successful (status code 2xx)
    if (response.status === 200 || response.status === 201) {
      // Fetch updated event data after successful update
      fetchJobs();
      handleCloseEditDialog();
    } else {
      // Log error if response status code is not in the 2xx range
      console.error("Failed to update event data. Unexpected status code:", response.status);
    }
  } catch (err) {
    // Log detailed error message
    console.error("Error updating event data:", err);

    // Check if the error has a response and log the server error message
    if (err.response) {
      console.error("Server error message:", err.response.data);
    }
  }
};


const handleSearch = (searchParams) => {
  const filtered = jobsData.filter(job => {
    return (
      (!searchParams.firstName || job.first_name.toLowerCase().includes(searchParams.firstName.toLowerCase())) &&
      (!searchParams.lastName || job.last_name.toLowerCase().includes(searchParams.lastName.toLowerCase()))&&
      (!searchParams.userId || job.userId.toLowerCase().includes(searchParams.userId.toLowerCase())) &&
      (!searchParams.telephoneNumber || job.telephoneNumber.toLowerCase().includes(searchParams.telephoneNumber.toLowerCase())) &&
      (!searchParams.email || job.email.toLowerCase().includes(searchParams.email.toLowerCase()))&& 
      (!searchParams.address || job.address.toLowerCase().includes(searchParams.address.toLowerCase())) 

      // Add other conditions for filtering here
    );
  });
  setFilteredData(filtered);
};

useEffect(() => {
  console.log('usersData:', usersData);
}, [usersData]);

const handleSearchByPhoneNumber = (phone) => {
  const formattedPhone = phone.trim().replace(/\s+/g, "");
  setPhoneSearch(formattedPhone);

  if (formattedPhone.length === 0) {
    // If the search input is empty, show all users
    setFilteredUsers(usersData); // Reset to show all users
  } else if (Array.isArray(usersData)) {
    // Filter users based on the search input
    const filtered = usersData.filter(user => {
      const formattedUserPhone = user.phone_number ? user.phone_number.trim().replace(/\s+/g, "") : "";
      return formattedUserPhone.includes(formattedPhone);
    });
    setFilteredUsers(filtered);
  } else {
    console.error("usersData is not an array:", usersData);
    setFilteredUsers([]); // Clear filtered users if usersData is not valid
  }
};










const handleUserSelection = (userId) => {
  const selected = usersData.find(user => user.id === userId);
  if (selected) {
    setSelectedUser(selected);
    setAssignCoachingData(prevData => ({ ...prevData, user: selected.id }));
  }
};

const handleCoachSelection = (coachId) => {
  setAssignCoachingData(prevData => ({ ...prevData, coach: coachId }));
};


useEffect(() => {
  console.log('coachesData:', coachesData); // Log coachesData after it is set
}, [coachesData]);

useEffect(() => {
  console.log('filteredUsers:', filteredUsers); // Log filtered users
}, [filteredUsers]);







return (
  <div>
      <h2>Coaches Information</h2>
      <FinanceSearch onSearch={handleSearch} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
      <Button variant="contained" onClick={handleOpenAssignCoachingPopup}  style={{margin:'10px'}}>
        Assign Coaches
      </Button>
      {/* <Button variant="contained" onClick={handleOpenCreateJobPopup} style={{margin:'10px'}} >
        Add Coaches
      </Button> */}
    </div>
      <Table>
<TableHead style={{ backgroundColor: '#DCD2D2', fontWeight: 'bold' }}>
  <TableRow>
    <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
    {/* <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell> */}
    {/* <TableCell style={{ fontWeight: 'bold' }}>Experiance Years</TableCell> */}
    {/* <TableCell style={{ fontWeight: 'bold' }}>Expertise</TableCell> */}
    {/* <TableCell style={{ fontWeight: 'bold' }}>Coach Fees</TableCell> */}
    <TableCell style={{ fontWeight: 'bold' }}>Phone Number</TableCell>
    <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
    {/* <TableCell style={{ fontWeight: 'bold' }}>Address</TableCell> */}
    {/* <TableCell style={{ fontWeight: 'bold' }}>Activites Name</TableCell> */}
    <TableCell  sx={{ fontWeight: 'bold', textAlign: 'right'}}>Actions</TableCell>
  </TableRow>
</TableHead>
<TableBody>
  {filteredData.map(jobs => (
    <TableRow key={jobs.id}>
      <TableCell>{jobs.first_name}</TableCell>
      {/* <TableCell>{jobs.last_name || '-'}</TableCell> */}
      {/* <TableCell>{jobs.experiance_years || 'null'}</TableCell>
      <TableCell>{jobs.expertise || 'null'}</TableCell> */}
      {/* <TableCell>{jobs.coach_fees || 'null'}</TableCell> */}
      <TableCell>{jobs.phone_number || '-'}</TableCell>
      <TableCell>{jobs.email || '-'}</TableCell>
      {/* <TableCell>{jobs.address || '-'}</TableCell> */}

      {/* <TableCell>{jobs.activities || '-'}</TableCell> */}
     
      <TableCell>
                <div style={{display:'flex', justifyContent:'flex-end'}}>
                <Button variant="contained"  color="primary"  onClick={()=>handleOpenDetailsDialog(jobs)} sx={{ marginRight: '8px' }}>Details</Button>
                <Button variant="contained"  color="primary" onClick={()=>handleOpenEditDialog(jobs)} >Edit</Button>
                
                </div>
                <Menu
                 
                >
                  <MenuItem>Enroll</MenuItem>
                  <MenuItem>Register</MenuItem>
                </Menu>
              </TableCell>
    </TableRow>
  ))}
</TableBody>
</Table>

<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button 
                    onClick={handlePreviousPage}
                    style={page <= 1 ? disabledButtonStyle : buttonStyle}
                    disabled={page <= 1}
                >
                    Previous
                </Button>
                <Button 
                    onClick={handleNextPage}
                    style={page >= totalPages ? disabledButtonStyle : buttonStyle}
                    
                >
                    Next
                </Button>
            </div>

<Dialog open={openCreateJobPopup} onClose={handleCloseCreateJobPopup}>
  <DialogTitle  variant="h6"  marginTop={'10px'} marginBottom={'20px'} display={'flex'} alignItems={'center'} style={{ fontWeight: 'bold' }} justifyContent={'center'}>Create Coach</DialogTitle>
  <DialogContent sx={{ width: '400px' }}>
    <Typography variant="subtitle1" gutterBottom>First Name</Typography>
    <TextField
      fullWidth
      label="First Name"
      name="first_name"
      value={newJobData.first_name}
      onChange={(e) => handleChange(e, true)}
      margin="normal"
    />

    <Typography variant="subtitle1" gutterBottom>Last Name</Typography>
    <TextField
      fullWidth
      label="Last Name"
      name="last_name"
      value={newJobData.last_name}
      onChange={(e) => handleChange(e, true)}
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
    />

    <Typography variant="subtitle1" gutterBottom>Min Experience</Typography>
    <TextField
      fullWidth
      label="Min Experience"
      name="experiance_years"
      value={newJobData.experiance_years}
      onChange={(e) => handleChange(e, true)}
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
    />

    <Typography variant="subtitle1" gutterBottom>Expertise</Typography>
    <TextField
      fullWidth
      label="Expertise"
      name="expertise"
      value={newJobData.expertise}
      onChange={(e) => handleChange(e, true)}
      margin="normal"
    />

<Typography variant="subtitle1" gutterBottom>Expertise</Typography>
    <TextField
      fullWidth
      label="Coach Fees"
      name="coach_fees"
      value={newJobData.coach_fees}
      onChange={(e) => handleChange(e, true)}
      margin="normal"
    />

    <Typography variant="subtitle1" gutterBottom>Phone Number</Typography>
    <TextField
      fullWidth
      label="Phone Number"
      name="phone_number"
      value={newJobData.phone_number}
      onChange={(e) => handleChange(e, true)}
      margin="normal"
    />

    <Typography variant="subtitle1" gutterBottom>Email</Typography>
    <TextField
      fullWidth
      label="Email"
      name="email"
      value={newJobData.email}
      onChange={(e) => handleChange(e, true)}
      margin="normal"
    />

    <Typography variant="subtitle1" gutterBottom>Address</Typography>
    <TextField
      fullWidth
      label="Address"
      name="address"
      value={newJobData.address}
      onChange={(e) => handleChange(e, true)}
      margin="normal"
    />
  </DialogContent>
  <DialogActions>
    <Button variant="contained" onClick={handleCloseCreateJobPopup} color="primary">
      Cancel
    </Button>
    <Button variant="contained" onClick={handleCreateJobs} color="primary">
      Create
    </Button>
  </DialogActions>
</Dialog>


    <Dialog
open={openDetailsDialog}
onClose={handleCloseDetailsDialog}
maxWidth={false}
      PaperProps={{
        style: {
          width: '80vw', // Adjust the width as needed
          height: '90vh', // Adjust the height as needed
          margin: 'auto',
        },
      }}
>
{/* <DialogTitle>Activity Details</DialogTitle> */}
<DialogContent>
  <Tabs
    value={selectedTab}
    onChange={(event, newValue) => setSelectedTab(newValue)}
    indicatorColor="primary"
    textColor="primary"
    centered
  >
    <Tab label="Details" value="details" sx={{ color: 'black', fontWeight: 'bold' }}/>
    <Tab label="Enroll" value="enroll" sx={{ color: 'black', fontWeight: 'bold' }} />
    <Tab label="Reserve" value="reserve" sx={{ color: 'black', fontWeight: 'bold' }} />
  </Tabs>
  {selectedTab === 'details' && (
<div>
<Typography variant="h6"  marginTop={'10px'} marginBottom={'20px'} display={'flex'} alignItems={'center'} style={{ fontWeight: 'bold' }} justifyContent={'center'}>Coach Details</Typography>
  <TableContainer>
<Table>
  <TableBody>
    <TableRow>
      <TableCell>First Name</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.first_name : ''}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>Last Name</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.last_name : ''}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>Experiance Years</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.experiance_years : 'Null'}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>Expertise</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.expertise : 'Null'}</TableCell>
    </TableRow>

    {/* <TableRow>
      <TableCell>Coach Fees</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.coach_fees : 'Null'}</TableCell>
    </TableRow> */}
    <TableRow>
      <TableCell>Phone Number</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.phone_number : 'Null'}</TableCell>
    </TableRow>

    <TableRow>
      <TableCell>Email</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.email : 'Null'}</TableCell>
    </TableRow>

    <TableRow>
      <TableCell>Address</TableCell>
      <TableCell>{selectedJobDetails ? selectedJobDetails.address : 'Null'}</TableCell>
    </TableRow>
   
  </TableBody>
</Table>
</TableContainer>

</div>
)}

  {selectedTab === 'enroll' && (
    <div>
      {/* Enroll content */}
    </div>
  )}
  {selectedTab === 'reserve' && (
    <div>
      {/* Reserve content */}
    </div>
  )}
</DialogContent>
<DialogActions>
  <Button onClick={handleCloseDetailsDialog} variant="contained" color="primary">
    Close
  </Button>
</DialogActions>
</Dialog>


<Dialog open={openEditDialog} onClose={handleCloseEditDialog} maxWidth={false}
      PaperProps={{
        style: {
          width: '60vw', // Adjust the width as needed
          height: '90vh', // Adjust the height as needed
          margin: 'auto',
        },
      }}>
      <DialogTitle 
    sx={{ 
        textAlign: 'center' ,
        fontWeight: 'bold',
        fontSize: '1.5rem'
    }}
>
    Edit Coaches
</DialogTitle>

      <DialogContent>
      <Typography variant="subtitle1" gutterBottom>First Name</Typography>
        <TextField
          fullWidth
          // label="Event Name"
          placeholder='First Name'
          name="first_name"
          value={editedEventData.first_name}
          onChange={handleChange}
          margin="normal"
        />
        <Typography variant="subtitle1" gutterBottom>Last Name</Typography>
        <TextField
          fullWidth
          // label="HEntry"
          name="last_name"
          value={editedEventData.last_name}
          onChange={handleChange}
          margin="normal"
        />

{/* <Typography variant="subtitle1" gutterBottom>Registered Peoples</Typography>
        <TextField
          fullWidth
          // label="Monthly Fees"
          name="registered_peoples"
          value={editedEventData.registered_peoples}
          onChange={handleChange}
          margin="normal"
        />
        <Typography variant="subtitle1" gutterBottom>Maximum Participants</Typography>
        <TextField
          fullWidth
          // label="Max Team Size"
          name="maximum_participants"
          value={editedEventData.maximum_participants}
          onChange={handleChange}
          margin="normal"
        /> */}

<Typography variant="subtitle1" gutterBottom>Experiance Years</Typography>
        <TextField
          fullWidth
          // label="Max Time Limit"
          name="experiance_years"
          value={editedEventData.experiance_years}
          
          onChange={handleChange}
          margin="normal"
        />
        <Typography variant="subtitle1" gutterBottom>Expertise </Typography>
        <TextField
          fullWidth
          // label="Available Court"
          name="expertise"
          value={editedEventData.expertise}
      
          onChange={handleChange}
          margin="normal"
        />

{/* <Typography variant="subtitle1" gutterBottom>Coach Fees </Typography>
        <TextField
          fullWidth
          // label="Available Court"
          name="coach_fees"
          value={editedEventData.coach_fees}
      
          onChange={handleChange}
          margin="normal"
        /> */}
 <Typography variant="subtitle1" gutterBottom>Phone Number </Typography>
<TextField
          fullWidth
          // label="Available Court"
          name="phone_number"
          value={editedEventData.phone_number}
          
          onChange={handleChange}
          margin="normal"
        />
 <Typography variant="subtitle1" gutterBottom>Email </Typography>
<TextField
          fullWidth
          // label="Available Court"
          name="email"
          value={editedEventData.email}
          
          onChange={handleChange}
          margin="normal"
        />
 <Typography variant="subtitle1" gutterBottom>Address </Typography>
<TextField
          fullWidth
          // label="Available Court"
          name="address"
          value={editedEventData.address}
          
          onChange={handleChange}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseEditDialog} color="primary" variant='contained'>
          Cancel
        </Button>
        <Button onClick={handleSaveChanges} color="primary" variant='contained'>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog open={openAssignCoachingPopup} onClose={handleCloseAssignCoachingPopup}>
  <DialogTitle>Assign Coaching</DialogTitle>
  <DialogContent>
    <TextField
      fullWidth
      label="Search by Phone Number"
      value={phoneSearch}
      onChange={(e) => handleSearchByPhoneNumber(e.target.value)}
      margin="normal"
    />

    <FormControl fullWidth style={{ marginTop: '10px' }}>
      <InputLabel>User</InputLabel>
      <Select
        value={selectedUser?.id || ""} // Use optional chaining for selected user
        onChange={(e) => handleUserSelection(e.target.value)}
        name="user"
      >
        {/* Show users based on search input */}
        {(phoneSearch.length === 0 ? usersData : filteredUsers).map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {`${user.first_name} ${user.last_name}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

    {/* Display Selected User */}
    {selectedUser && (
      <TextField
        fullWidth
        label="Selected User"
        value={`${selectedUser.first_name} ${selectedUser.last_name}`}
        InputProps={{
          readOnly: true,
        }}
        margin="normal"
      />
    )}

    {/* Coach Selection */}
    <FormControl fullWidth style={{ marginTop: '10px' }}>
      <InputLabel>Coach</InputLabel>
      <Select
        value={assignCoachingData.coach}
        onChange={(e) => handleCoachSelection(e.target.value)}
        name="coach"
        disabled={!selectedUser} // Enable if a user is selected
      >
        {coachesData.length > 0 ? (
          coachesData.map((coach) => (
            <MenuItem key={coach.id} value={coach.id}>
              {`${coach.first_name} ${coach.last_name}`}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No coaches available</MenuItem>
        )}
      </Select>
    </FormControl>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseAssignCoachingPopup} variant="contained" color="secondary">
      Cancel
    </Button>
    <Button
      onClick={handleAssignCoaching}
      color="primary"
      variant="contained"
      disabled={!selectedUser || !assignCoachingData.coach} // Disable if no user or coach is selected
    >
      Assign
    </Button>
  </DialogActions>
</Dialog>








  </div>
)
}

export default Coaches