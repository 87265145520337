import React from 'react'
import  { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Menu,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from '@mui/material';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import FinanceSearch from '../SearchComponent/FinanceSearch';
const Registration = () => {
    const[jobsData,setJobsData]=useState([]);
    const [openCreateJobPopup,SetOpenCreateJobPopup]=useState(false);
    const [usersData, setUsersData] = useState([]);
    const [coachesData, setCoachesData] = useState([]);
    const [selectedJobDetails, setSelectedJobDetails] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [selectedTab, setSelectedTab] = useState('details');
    const [selectedJob, setSelectedJob] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [phoneSearch, setPhoneSearch] = useState("");
    const [newJobData, setNewJobData] = useState({
      "first_name": "",
      "last_name": "",
      "experiance_years": "",
      "expertise": "",
      "coach_fees":"",
      "phone_number": "",
      "email": "",
      "address": ""
    });
    
    const [editedEventData, setEditedEventData] = useState({
      "first_name": "",
      "last_name": "",
      "experiance_years": "",
      "expertise": "",
      "coach_fees":"",
      "phone_number": "",
      "email": "",
      "address": ""
    });
    
    const [assignCoachingData, setAssignCoachingData] = useState({
      user: "",
      coach: ""
    });
    const [openAssignCoachingPopup, setOpenAssignCoachingPopup] = useState(false);

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/users/users/`);
        setUsersData(response.data.results || response.data); // Adjust based on the API response structure
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
  

    
    const fetchCoaches = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/activity`);
        setCoachesData(response.data);
      } catch (error) {
        console.error("Error fetching coaches:", error);
      }
    };
  
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/booked-registrations/`);
        setJobsData(response.data);
        setFilteredData(response.data);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    }
  
    const handleAssignCoaching = async () => {
        try {
          const selectedActivity = coachesData.find(coach => coach.id === assignCoachingData.coach);
    
          if (!selectedActivity) {
            alert('Error', 'Please select an activity.');
            return;
          }
    
          const activityPrice = selectedActivity.price; // Assuming `price` is in rupees
          const amountInPaise = activityPrice * 100; // Convert to paise
    
          const userEmail = selectedUser.email; // Accessing the email property of the user
    
          const payload = {
            user: selectedUser.id,
            activity: selectedActivity.id,
          };
    
          console.log('Request Data:', payload);
    
          // Example Razorpay options, replace with your actual implementation
          const razorpayOptions = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            key_secret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
            amount: amountInPaise, // Amount in paise
            currency: 'INR',
            name: 'Activity Booking',
            description: 'Activity Booking',
            image: '', // Your logo URL
            order_id: '', // Leave empty for now
            handler: async function (response) {
              try {
                const reservationResponse = await axios.post(`${backendUrl}/api/activity-registrations/`, payload);
                console.log('Reservation Response:', reservationResponse.data);
    
                const invoiceId = reservationResponse.data.invoice_id;
    
                await axios.put(`${backendUrl}/api/invoice/${invoiceId}`, {
                  is_paid: true
                });
    
                alert('Success', 'Reservation successful!');
    
                // Handle successful assignment
                fetchJobs();
                handleCloseAssignCoachingPopup();
              } catch (error) {
                console.error('Error making reservation:', error);
                alert('Error', 'Reservation failed. Please try again.');
              }
            },
            prefill: {
              name: '', // Prefill user's name if available
              email: userEmail, // Prefill user's email
              contact: '9999999999', // Prefill user's contact number if available
            },
            theme: {
              color: '#f26d21', // Your theme color
            },
          };
    
          try {
            const razorpayInstance = new window.Razorpay(razorpayOptions);
            razorpayInstance.open();
          } catch (error) {
            console.error("Error handling payment:", error);
          }
        } catch (err) {
          console.log(err);
        }
      };
      

  
    const handleOpenAssignCoachingPopup = () => {
      setOpenAssignCoachingPopup(true);
    };
  
    const handleCloseAssignCoachingPopup = () => {
      setOpenAssignCoachingPopup(false);
    };
  
  
    const handleCreateJobs = async () => {
      try {
        const payload = {
          first_name: newJobData.first_name,
          last_name: newJobData.last_name,
          experiance_years: newJobData.experiance_years,
          expertise: newJobData.expertise,
          phone_number: newJobData.phone_number,
          email: newJobData.email,
          address: newJobData.address
        };
    
        const response = await axios.post(`${backendUrl}/api/coaches/`, payload);
        
        // Handle successful creation
        fetchJobs();
        handleCloseCreateJobPopup();
    
      } catch (err) {
        console.log(err);
      }
    };
    
    const handleOpenCreateJobPopup = () => {
      SetOpenCreateJobPopup(true)
    };
  
    const handleCloseCreateJobPopup = () => {
      SetOpenCreateJobPopup(false);
    };
  
    const handleChange = (e, isCreating, isAssigning) => {
      const { name, value } = e.target;
      if (isCreating) {
        setNewJobData(prevData => ({
          ...prevData,
          [name]: value
        }));
      } else if (isAssigning) {
        setAssignCoachingData(prevData => ({
          ...prevData,
          [name]: value
        }));
      } else {
        setEditedEventData(prevData => ({
          ...prevData,
          [name]: value
        }));
      }
    };
  
    useEffect(()=>{
            fetchJobs();
            fetchUsers();
      fetchCoaches();
    },[])
  
    const handleOpenDetailsDialog = (jobs) => {
      setSelectedJobDetails(jobs); // Make sure this job is not null
      setOpenDetailsDialog(true);
      console.log("Selected Job Details:", jobs); // Ensure this shows the correct details
  };
  
    
  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
  };
  
  
  const handleOpenEditDialog = (jobs) => {
  setSelectedJob(jobs);
  const editedData = { ...jobs };
  setEditedEventData(editedData);
  setOpenEditDialog(true); // Make sure this line is present
  };
  
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };
  
  const handleSaveChanges = async () => {
    try {
      // Check if selectedEvent is not null
      if (!selectedJob) {
        console.error("No event selected for editing.");
        return;
      }
  
      // Send PUT request to update event data
      const response = await axios.put(`${backendUrl}/api/coaches/${selectedJob.id}/`, editedEventData);
  
      // Check if the request was successful (status code 2xx)
      if (response.status === 200 || response.status === 201) {
        // Fetch updated event data after successful update
        fetchJobs();
        handleCloseEditDialog();
      } else {
        // Log error if response status code is not in the 2xx range
        console.error("Failed to update event data. Unexpected status code:", response.status);
      }
    } catch (err) {
      // Log detailed error message
      console.error("Error updating event data:", err);
  
      // Check if the error has a response and log the server error message
      if (err.response) {
        console.error("Server error message:", err.response.data);
      }
    }
  };
  
  
  const handleSearch = (searchParams) => {
    const filtered = jobsData.filter(job => {
      return (
        (!searchParams.firstName || job.user_first_name.toLowerCase().includes(searchParams.firstName.toLowerCase())) &&
        (!searchParams.lastName || job.user_last_name.toLowerCase().includes(searchParams.lastName.toLowerCase()))&&
        (!searchParams.userId || job.userId.toLowerCase().includes(searchParams.userId.toLowerCase())) &&
        (!searchParams.telephoneNumber || job.telephoneNumber.toLowerCase().includes(searchParams.telephoneNumber.toLowerCase())) &&
        (!searchParams.email || job.email.toLowerCase().includes(searchParams.email.toLowerCase()))&& 
        (!searchParams.address || job.activity_name.toLowerCase().includes(searchParams.address.toLowerCase())) 
  
        // Add other conditions for filtering here
      );
    });
    setFilteredData(filtered);
  };
  
  const handleSearchByPhoneNumber = (event) => {
    const phoneNumber = event.target.value.toLowerCase().trim();
    setPhoneSearch(phoneNumber);

    if (phoneNumber === '') {
      setFilteredUsers([]); // Clear filtered users if the search is empty
      return;
    }

    const filtered = usersData.filter((user) =>
      user.phone_number && user.phone_number.toLowerCase().includes(phoneNumber)
    );

    setFilteredUsers(filtered);
  };

  
  
  
  const handleUserSelection = (user) => {
    setSelectedUser(user);
    setAssignCoachingData((prevData) => ({
      ...prevData,
      user: user.id, // Set the user ID for assignment
    }));
    setFilteredUsers([]); // Clear filtered users list after selection
  };
  
  
  
  
  const handleCoachSelection = (e) => {
    const { name, value } = e.target;
    setAssignCoachingData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  
  
  
  return (
    <div>
        <h2>Register Information</h2>
        <FinanceSearch onSearch={handleSearch} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
        <Button variant="contained" onClick={handleOpenAssignCoachingPopup}  style={{margin:'10px'}}>
          Create Register
        </Button>
        {/* <Button variant="contained" onClick={handleOpenCreateJobPopup} style={{margin:'10px'}} >
          Add Coaches
        </Button> */}
      </div>
        <Table>
  <TableHead style={{ backgroundColor: '#DCD2D2', fontWeight: 'bold' }}>
    <TableRow>
      <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Activity Name</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Register Date</TableCell>
      {/* <TableCell style={{ fontWeight: 'bold' }}>Coach Fees</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Phone Number</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
      <TableCell style={{ fontWeight: 'bold' }}>Address</TableCell> */}
      {/* <TableCell style={{ fontWeight: 'bold' }}>Activites Name</TableCell> */}
      <TableCell  sx={{ fontWeight: 'bold', textAlign: 'right'}}>Actions</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {filteredData.map(jobs => (
      <TableRow key={jobs.id}>
        <TableCell>{jobs.user_first_name}</TableCell>
        <TableCell>{jobs.user_last_name || '-'}</TableCell>
        <TableCell>{jobs.activity_name || 'null'}</TableCell>
        <TableCell>
  {jobs.registered_date ? new Date(jobs.registered_date).toLocaleString() : 'null'}
</TableCell>

        {/* <TableCell>{jobs.coach_fees || 'null'}</TableCell>
        <TableCell>{jobs.phone_number || '-'}</TableCell>
        <TableCell>{jobs.email || '-'}</TableCell>
        <TableCell>{jobs.address || '-'}</TableCell>
   */}
        {/* <TableCell>{jobs.activities || '-'}</TableCell> */}
       
        <TableCell>
                  <div style={{display:'flex', justifyContent:'space-around'}}>
                  <Button variant="contained"  color="primary"  onClick={()=>handleOpenDetailsDialog(jobs)}>Details</Button>
                  <Button variant="contained"  color="primary" onClick={()=>handleOpenEditDialog(jobs)} >Edit</Button>
                  
                  </div>
                  <Menu
                   
                  >
                    <MenuItem>Enroll</MenuItem>
                    <MenuItem>Register</MenuItem>
                  </Menu>
                </TableCell>
      </TableRow>
    ))}
  </TableBody>
  </Table>
  
  <Dialog open={openCreateJobPopup} onClose={handleCloseCreateJobPopup}>
    <DialogTitle>Create Activity</DialogTitle>
    <DialogContent sx={{ width: '400px' }}>
      <Typography variant="subtitle1" gutterBottom>First Name</Typography>
      <TextField
        fullWidth
        label="First Name"
        name="first_name"
        value={newJobData.first_name}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
      />
  
      <Typography variant="subtitle1" gutterBottom>Last Name</Typography>
      <TextField
        fullWidth
        label="Last Name"
        name="last_name"
        value={newJobData.last_name}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
  
      <Typography variant="subtitle1" gutterBottom>Min Experience</Typography>
      <TextField
        fullWidth
        label="Min Experience"
        name="experiance_years"
        value={newJobData.experiance_years}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
  
      <Typography variant="subtitle1" gutterBottom>Expertise</Typography>
      <TextField
        fullWidth
        label="Expertise"
        name="expertise"
        value={newJobData.expertise}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
      />
  
  <Typography variant="subtitle1" gutterBottom>Expertise</Typography>
      <TextField
        fullWidth
        label="Coach Fees"
        name="coach_fees"
        value={newJobData.coach_fees}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
      />
  
      <Typography variant="subtitle1" gutterBottom>Phone Number</Typography>
      <TextField
        fullWidth
        label="Phone Number"
        name="phone_number"
        value={newJobData.phone_number}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
      />
  
      <Typography variant="subtitle1" gutterBottom>Email</Typography>
      <TextField
        fullWidth
        label="Email"
        name="email"
        value={newJobData.email}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
      />
  
      <Typography variant="subtitle1" gutterBottom>Address</Typography>
      <TextField
        fullWidth
        label="Address"
        name="address"
        value={newJobData.address}
        onChange={(e) => handleChange(e, true)}
        margin="normal"
      />
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={handleCloseCreateJobPopup} color="primary">
        Cancel
      </Button>
      <Button variant="contained" onClick={handleCreateJobs} color="primary">
        Create
      </Button>
    </DialogActions>
  </Dialog>
  
  
      <Dialog
  open={openDetailsDialog}
  onClose={handleCloseDetailsDialog}
  PaperProps={{
    style: {
        width: '90vw', // Adjust the width as needed (increased to 90%)
        height: '100vh',
        margin: 'auto',
        maxWidth: '1400px', // Increased maximum width for large screens
    },
}}
  >
  {/* <DialogTitle>Activity Details</DialogTitle> */}
  <DialogContent>
    <Tabs
      value={selectedTab}
      onChange={(event, newValue) => setSelectedTab(newValue)}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab label="Details" value="details" />
      <Tab label="Enroll" value="enroll" />
      <Tab label="Reserve" value="reserve" />
    </Tabs>
    {selectedTab === 'details' && (
  <div>
    <Typography variant="h6">Register Details</Typography>
    <TableContainer>
  <Table>
    <TableBody>
      <TableRow>
        <TableCell>First Name</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.user_first_name : 'Loading...'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Last Name</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.user_last_name : ''}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Register Date</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.registered_date : 'Null'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Activity Name</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.activity_name : 'Null'}</TableCell>
      </TableRow>
  
      {/* <TableRow>
        <TableCell>Coach Fees</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.user_coach_fees : 'Null'}</TableCell>
      </TableRow> */}
      {/* <TableRow>
        <TableCell>Phone Number</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.user_phone_number : 'Null'}</TableCell>
      </TableRow> */}
  
      {/* <TableRow>
        <TableCell>Email</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.user_email : 'Null'}</TableCell>
      </TableRow> */}
  
      {/* <TableRow>
        <TableCell>Address</TableCell>
        <TableCell>{selectedJobDetails ? selectedJobDetails.user_address : 'Null'}</TableCell>
      </TableRow> */}
     
    </TableBody>
  </Table>
  </TableContainer>
  
  </div>
  )}
  
    {selectedTab === 'enroll' && (
      <div>
        {/* Enroll content */}
      </div>
    )}
    {selectedTab === 'reserve' && (
      <div>
        {/* Reserve content */}
      </div>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDetailsDialog} color="primary" >
      Close
    </Button>
  </DialogActions>
  </Dialog>
  
  
  <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Activity</DialogTitle>
        <DialogContent>
        <Typography variant="subtitle1" gutterBottom>First Name</Typography>
          <TextField
            fullWidth
            // label="Event Name"
            placeholder='First Name'
            name="first_name"
            value={editedEventData.first_name}
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>Last Name</Typography>
          <TextField
            fullWidth
            // label="HEntry"
            name="last_name"
            value={editedEventData.last_name}
            onChange={handleChange}
            margin="normal"
          />
  
  {/* <Typography variant="subtitle1" gutterBottom>Registered Peoples</Typography>
          <TextField
            fullWidth
            // label="Monthly Fees"
            name="registered_peoples"
            value={editedEventData.registered_peoples}
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>Maximum Participants</Typography>
          <TextField
            fullWidth
            // label="Max Team Size"
            name="maximum_participants"
            value={editedEventData.maximum_participants}
            onChange={handleChange}
            margin="normal"
          /> */}
  
  <Typography variant="subtitle1" gutterBottom>Experiance Years</Typography>
          <TextField
            fullWidth
            // label="Max Time Limit"
            name="experiance_years"
            value={editedEventData.experiance_years}
            
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>Expertise </Typography>
          <TextField
            fullWidth
            // label="Available Court"
            name="expertise"
            value={editedEventData.expertise}
        
            onChange={handleChange}
            margin="normal"
          />
  
  <Typography variant="subtitle1" gutterBottom>Coach Fees </Typography>
          <TextField
            fullWidth
            // label="Available Court"
            name="coach_fees"
            value={editedEventData.coach_fees}
        
            onChange={handleChange}
            margin="normal"
          />
   <Typography variant="subtitle1" gutterBottom>Phone Number </Typography>
  <TextField
            fullWidth
            // label="Available Court"
            name="phone_number"
            value={editedEventData.phone_number}
            
            onChange={handleChange}
            margin="normal"
          />
   <Typography variant="subtitle1" gutterBottom>Email </Typography>
  <TextField
            fullWidth
            // label="Available Court"
            name="email"
            value={editedEventData.email}
            
            onChange={handleChange}
            margin="normal"
          />
   <Typography variant="subtitle1" gutterBottom>Address </Typography>
  <TextField
            fullWidth
            // label="Available Court"
            name="address"
            value={editedEventData.address}
            
            onChange={handleChange}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveChanges} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
  
      <Dialog open={openAssignCoachingPopup} onClose={handleCloseAssignCoachingPopup}>
  <DialogTitle>Register Sports</DialogTitle>
  <DialogContent>
    {/* User Search by Phone Number */}
    <TextField
      fullWidth
      label="Search by Phone Number"
      onChange={(e) => handleSearchByPhoneNumber(e)} // Pass the entire event for consistency
      margin="normal"
    />

    {/* Display Filtered Users */}
    {filteredUsers.length > 0 ? (
      <FormControl fullWidth style={{ marginTop: '10px' }}>
        <InputLabel>User</InputLabel>
        <Select
          value={assignCoachingData.user || ''} // Default value
          onChange={(e) => {
            const selectedUser = filteredUsers.find(user => user.id === e.target.value); // Use filteredUsers instead
            handleUserSelection(selectedUser); // Call the selection handler
          }}
          name="user"
        >
          {filteredUsers.map(user => (
            <MenuItem key={user.id} value={user.id}>{`${user.first_name} ${user.last_name}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : (
      <Typography variant="body2" style={{ marginTop: '10px' }}>
        No users found matching the phone number.
      </Typography>
    )}

    {/* Selected User Display */}
    {selectedUser && (
      <TextField
        fullWidth
        label="Selected User"
        value={`${selectedUser.first_name} ${selectedUser.last_name}`} // Display full name
        InputProps={{
          readOnly: true,
        }}
        margin="normal"
      />
    )}

    {/* Coach Selection */}
    <FormControl fullWidth style={{ marginTop: '10px' }}>
      <InputLabel>Coaches</InputLabel>
      <Select
        value={assignCoachingData.coach || ''} // Default value
        onChange={handleCoachSelection}
        name="coach"
      >
        {coachesData.map(coach => (
          <MenuItem key={coach.id} value={coach.id}>{`${coach.activity_name}`}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseAssignCoachingPopup} color="secondary">
      Cancel
    </Button>
    <Button onClick={handleAssignCoaching} color="primary" disabled={!selectedUser || !assignCoachingData.coach}>
      Assign
    </Button>
  </DialogActions>
</Dialog>


  
  
  
    </div>
  )
  }

export default Registration